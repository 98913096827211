import React from 'react'
// import Question from './Question'
import { Link } from 'react-router-dom'
import zustechIcon from './assets/zustechIcon.svg'
import profileIcon from './assets/profileIcon.svg'
import paymentWithdrawalIcon from './assets/paymentWithdrawal-Icon.svg'
import createCourseIcon from './assets/createCourseIcon.svg'
import spannerIcon from './assets/spannerIcon.svg'

const InstructorContactExplore = () => {
    // const navigate = useNavigate()
    return (
        <>
            {/* BOTTOM SECTION EXPLORE */}
            <section className="bottom-section">
                <h3>Further Topics to Explore</h3>
                <div className="topics-explore">
                    <Link
                        to="/instructor-support/getting-started"
                        className="explore-links"
                    >
                        <img
                            src={zustechIcon}
                            className="zustech-icon"
                            alt=""
                        />
                        <h3>Getting Started</h3>
                        <p>Learn how Zustech works</p>
                    </Link>
                    <Link
                        to="/instructor-support/account-setting"
                        className="explore-links"
                    >
                        <img
                            src={profileIcon}
                            className="profile-icon"
                            alt=""
                        />
                        <h3>Accounts/Profile</h3>
                        <p>Manage your accounts and profile settings</p>
                    </Link>
                    <Link
                        to="/instructor-support/payment-withdrawal"
                        className="explore-links"
                    >
                        <img
                            src={paymentWithdrawalIcon}
                            className="paymentWithdrawal-icon"
                            alt=""
                        />
                        <h3>Payment and Withdrawal</h3>
                        <p>Learn about payments and revenue allocation</p>
                    </Link>
                    <Link
                        to="/instructor-support/create-course"
                        className="explore-links"
                    >
                        <img
                            src={createCourseIcon}
                            className="createCourse-icon"
                            alt=""
                        />
                        <h3>Create courses</h3>
                        <p>Create your course materials and curriculum</p>
                    </Link>
                    <Link
                        to="/instructor-support/troubleshoot"
                        className="explore-links"
                    >
                        <img
                            src={spannerIcon}
                            className="troubleshoot-icon"
                            alt=""
                        />
                        <h3>Troubleshooting</h3>
                        <p>Having technical problems Check here.</p>
                    </Link>
                </div>
                <p className="here">
                    You can contact us <Link to="/contact-form">here</Link> for
                    more assistance.
                </p>
            </section>
        </>
    )
}

export default InstructorContactExplore;