// import components
import HeroInstructor from './HeroInstructor'
import CtaIns from './CtaIns'
import Why from './WhyBecomeInstructor'
import How from './HowItWorks'
import { Testimonial } from '../shared'
import What from './WhatItTakes'
// import Footer from "../homePage/footer/Footer";
// import styles
import './instructorPage.scss'

export const InstructorPage = () => {
    return (
        <>
            <HeroInstructor />
            <Why />
            <How />
            <section id="testimonial" style={{ paddingBottom: '10px' }}>
                <Testimonial title="What our teachers think" />
            </section>
            <What />
            <CtaIns />
        </>
    )
}
