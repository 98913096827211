import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import '../studentsupport.scss'
import { MdArrowForwardIos } from 'react-icons/md'
import { VscChevronDown, VscChevronUp } from 'react-icons/vsc'
import group1 from '../assets/group1.svg'
import group2 from '../assets/group2.svg'
// import Questions from '../ContactUs/Question'
import { getFaq, getFaqRes } from '../../../../../services'

const SubscriptionBilling = () => {
    const [showInfo, setShowInfo] = useState(false)
    const [questions, setQuestions] = useState([])
    const [responses, setResponses] = useState([])
    const history = useHistory()
    // const newResponse = responses.filter(response => response.questionId === questions.questionId)
    // const { params } = useParams()

    // GET QUESTION ENDPOINT
    useEffect(() => {
        const getFAQ = async () => {
            try {
                const response = await getFaq()
                setQuestions(response?.data)
                // setAnswers(response?.data?.faqResponseModels)
            } catch (error) {
                console.error(error)
            }
        }
        getFAQ()
    }, [])

    // GET RESPONSE ENDPOINT
    useEffect(() => {
        const getFAQRes = async () => {
            try {
                const response = await getFaqRes()
                setResponses(response?.data)
            } catch (error) {
                console.error(error)
            }
        }
        getFAQRes()
    }, [])

    return (
        <div id="student-support">
            <div className="top-background">
                <img src={group1} alt="" className="group1-img" />
                <img src={group2} alt="" className="group2-img" />
                <input
                    placeholder="Search articles"
                    name="search-article"
                    id="search-article"
                    className="search-article"
                />
            </div>
            <div className="top-link">
                <a href="/s">Students support</a>
                <MdArrowForwardIos />
                <a href="/s">Accounts</a>
                <MdArrowForwardIos />
            </div>
            <nav className="nav-btns">
                <button
                    onClick={() => {
                        history.push('/student-support/getting-started')
                    }}
                    className="btn"
                >
                    Getting started
                </button>
                <button
                    onClick={() => {
                        history.push('/student-support/account-setting')
                    }}
                    className="btn"
                >
                    Account/Profile
                </button>
                <button
                    onClick={() => {
                        history.push('/student-support/subscription')
                    }}
                    className="btn"
                >
                    Subscription/billing
                </button>
                <button
                    onClick={() => {
                        history.push('/student-support/take-course')
                    }}
                    className="btn"
                >
                    Take course
                </button>
                <button
                    onClick={() => {
                        history.push('/student-support/troubleshoot')
                    }}
                    className="btn"
                >
                    Troubleshooting
                </button>
            </nav>
            <section className="content-settings">
                <h2>Subcription & Billing</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                </p>
                <div className="questions-container">
                    <div className="questions questions-box-1">
                        {questions.map((question) => {
                            const newResponse = responses.filter(
                                (response) =>
                                    response.questionId === question.questionId
                            )
                            return (
                                <div className="article-faq">
                                    <div
                                        className="title-faq"
                                        onClick={() => {
                                            setShowInfo(!showInfo)
                                        }}
                                    >
                                        <h4 key={question.questionId}>
                                            {question.question}
                                        </h4>
                                        <div
                                            className="btn-question"
                                            onClick={() => {
                                                setShowInfo(!showInfo)
                                            }}
                                        >
                                            {showInfo ? (
                                                <VscChevronUp />
                                            ) : (
                                                <VscChevronDown />
                                            )}
                                        </div>
                                    </div>
                                    {showInfo
                                        ? newResponse.map((response) => {
                                            return <p>{response.response}</p>
                                        })
                                        : null}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </div>
    )
}
export default SubscriptionBilling
