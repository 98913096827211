import axios from 'axios'
import { getAuthToken, clearStorage } from './helpers'

const options = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        Accept: 'application/json,text/plain,*/*',
        'Content-Type': 'application/json',
        // Country: localStorage.getItem('zustech:user:country'),
        Country: localStorage.getItem('currentCountry'),
    },
}

export const request = axios.create(options)
// const { location, userIP, city, region, country } = useGeoLocation();

request.interceptors.request.use(
    (config) => {
        const token = getAuthToken()
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

request.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        try {
            const errorData = error?.response
            if (errorData?.status === 401) {
                clearStorage()
                window.location.reload()
            }
            return Promise.reject(error)
        } catch (error) {
            return Promise.reject(error)
        }
    }
)