import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { softSearchCourses } from '../../../../services'

import './style.scss'

const SearchBar = () => {
    const [searchInput, setSearchInput] = useState('')
    const [searchResult, setSearchResult] = useState([])

    useEffect(() => {
        const handleChange = async (value) => {
            // const { value } = event.target
            // setInput(value)
            if (value !== '') {
                try {
                    // fetch data
                    const response = await softSearchCourses(value)
                    setSearchResult(response?.data)
                    // console.log(searchResult)
                } catch (error) {
                    console.log(error)
                }
            } else {
                setSearchResult([])
            }
        }

        handleChange(searchInput)

        return () => {
            setSearchResult([])
        }
    }, [searchInput])

    // const debouce = (func) => {
    //     let timeout
    //     return function (...args) {
    //         const context = this
    //         if (timeout) clearTimeout(timeout)
    //         timeout = setTimeout(() => {
    //             timeout = null
    //             func.apply(context, args)
    //         }, 500)
    //     }
    // }

    // const optimizedFunc = useCallback(debouce(handleChange), []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div id="search">
            <div className="search-input">
                <input
                    // onClick={optimizedFunc}
                    value={searchInput}
                    onChange={(e) => {
                        setSearchInput(e.target.value)
                    }}
                    type="text"
                    className="search-bar"
                    placeholder="Search Courses... "
                />
                {searchResult.length !== 0 && (
                    <div className="search-result">
                        {searchResult.map((value) => (
                            <Link
                                key={value.name}
                                to={`courses/course/${value?.id}`}
                            >
                                <div className="search-course-div">
                                    <h4>{value.name}</h4>
                                </div>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SearchBar
