import React, { useState } from 'react'
import './contactform.scss'
import { Form } from 'antd'
import { Input } from '../../../../components'
import { postContactForm } from '../../../../services'
import { openNotification } from '../../../../utils'
import { useAuth } from '../../../../hooks'

export const ContactForm = () => {
    const initialValues = { name: '', email: '', phone: '', message: '' }
    const [formValues, setFormValues] = useState(initialValues)
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSending, setIsSending] = useState(false)
    // const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    // const [emailError, setEmailError] = useState('')

    const { state: { isLoading } } = useAuth()

    // Ant Form
    const [form] = Form.useForm()

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormValues({ ...formValues, [name]: value })
    }

    const handleSubmit = async (values) => {
        try {
            setIsSending(true)
            const res = await postContactForm({
                ...values,
                name: formValues.name,
                email: formValues.email,
                phoneNumber: formValues.phone,
                message: formValues.message,
            })
            if (res.status === 200) {
                setIsSending(false)
                setIsSubmit(true)
            }
        } catch (error) {
            setIsSending(false)
            setIsSubmit(false)
            openNotification({
                type: 'error',
                title: 'Contact Form',
                message: error.message,
            })
        }
    }

    const handleSuccessMessage = () => {
        setIsSubmit(false)
        setIsSending(false)
        setFormValues(initialValues)
    }

    return (
        <div id="contact-form">
            <div className="section-1">
                <p className="section-1-head">Get in Touch</p>
                <p className="section-1-content">
                    We are delighted that you're reaching out to us. Kindly
                    share with us how we might help you.
                </p>
            </div>
            <div className="section-2">
                <p className="section-2-head">Contact Us</p>
                <hr />
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    autoComplete="on"
                    scrollToFirstError
                    // disabled={isSending}
                >
                    <input
                        className="name-input"
                        id="name"
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formValues.name}
                        disabled={isSending}
                        required
                        onChange={(e) => {
                            setFormValues({ ...formValues, 'name': e.target.value })
                        }}
                    />
                    <Input
                        className="email-input h-100"
                        // type="email"
                        id="email"
                        name="email"
                        placeholder="Enter email"
                        value={formValues.email}
                        required
                        onChange={(e) => {
                            setFormValues({ ...formValues, 'email': e.target.value })
                        }}
                        validationRules={[
                            {
                                required: true,
                                message: 'Please enter your email address',
                            },
                            {
                                type: 'email',
                                message: 'This email address is not valid',
                            },
                        ]}
                        disabled={isLoading || isSending}
                    />

                    <input
                        className="contact-input"
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="Contact No. (Optional)"
                        value={formValues.phone}
                        onChange={handleChange}
                        disabled={isSending}
                    />
                    <input
                        className="message-input"
                        type="text"
                        id="message"
                        name="message"
                        placeholder="Message"
                        required
                        value={formValues.message}
                        onChange={handleChange}
                        disabled={isSending}
                    />
                    <button
                        className="btn-submit"
                        type="submit"
                        disabled={isSending}
                    >
                        {!isSending ? 'Submit' : 'Submission in Progress...'}
                    </button>
                </Form>
            </div>
            {isSubmit ? (
                <div className="overlay" onClick={handleSuccessMessage}>
                    <div className="successMessage">
                        <h4 className="successMessage--text">
                            Thank you for contacting us!
                        </h4>
                        <p className="successMessage--subtext">
                            Message delivered successfully. We will give you
                            feedback as soon as possible.
                        </p>
                        <button
                            className="successMessage--btn"
                            onClick={handleSuccessMessage}
                        >
                            Continue
                        </button>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

// <pre>{JSON.stringify(formValues, undefined, 2)}</pre>