import React, { useContext, useEffect, useState } from 'react'
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js'
import { CartContext } from '../../../context/Cart/cartContext'
// import { openNotification } from '../../../utils/helpers'
import './checkoutStripe.scss'

export default function CheckoutForm() {
    const stripe = useStripe()
    const elements = useElements()

    const [email, setEmail] = useState('')
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    console.log(email)
    const [paymentSuccess, setPaymentSuccess] = useState('')
    const { clearCart } = useContext(CartContext)

    // const config = {
    //     development: {
    //         successUrl: 'http://localhost:3000/me/premium/success',
    //         failureUrl: 'http://localhost:3000/me/premium/cart',
    //     },
    //     production: {
    //         successUrl: 'https://zustech-lms-frontend.s3-website.us-east-2.amazonaws.com/me/premium/success',
    //         failureUrl: 'https://zustech-lms-frontend.s3-website.us-east-2.amazonaws.com/me/premium/cart',
    //     },
    // }
    // const [returnUrl, setReturnUrl] = useState(
    //     process.env.NODE_ENV === 'production'
    //         ? config.production.successUrl
    //         : config.development.successUrl
    // )
    const [returnUrl, setReturnUrl] = useState(
        'http://zustech-lms-frontend.s3-website.us-east-2.amazonaws.com/me/premium/success'
    )
    if (paymentSuccess === 'succeeded') {
        clearCart()
    }
    useEffect(() => {
        if (!stripe) {
            return
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
        )

        if (!clientSecret) {
            return
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage('Payment succeeded!');
                    setPaymentSuccess('succeeded');
                    clearCart();
                    // setTimeout(() => {
                    //     clearCart()
                    // }, 2000)
                    // return openNotification({
                    //     type: 'success',
                    //     title: 'Checkout',
                    //     message: 'Checkout Successful!',
                    // })
                    break
                case 'processing':
                    setMessage('Your payment is processing.')
                    break
                case 'requires_payment_method':
                    setMessage(
                        'Your payment was not successful, please try again.'
                    )
                    break
                default:
                    setMessage('Something went wrong.')
                    break
            }
            // clearCart()
        })
    }, [stripe, clearCart])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setIsLoading(true)

        const { error, result } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: returnUrl,
            },
        })

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error) {
            setReturnUrl('http://zustech-lms-frontend.s3-website.us-east-2.amazonaws.com/me/premium/cart')
            // setReturnUrl('http://localhost:3000/me/premium/cart')
            // setReturnUrl(
            //     process.env.NODE_ENV === 'production'
            //         ? config.production.failureUrl
            //         : config.development.failureUrl
            // )
            setMessage(error.message)
        } else {
            setReturnUrl('http://zustech-lms-frontend.s3-website.us-east-2.amazonaws.com/me/premium/cart')
            // setReturnUrl('http://localhost:3000/me/premium/cart')
            // setReturnUrl(
            //     process.env.NODE_ENV === 'production'
            //         ? config.production.failureUrl
            //         : config.development.failureUrl
            // )
            setMessage('An unexpected error occurred.')
        }
        setIsLoading(false)
        if (result.paymentIntent.status === 'succeeded') {
            setReturnUrl('http://zustech-lms-frontend.s3-website.us-east-2.amazonaws.com/me/premium/success')
            // setReturnUrl('http://localhost:3000/me/premium/success')
            // setReturnUrl(
            //     process.env.NODE_ENV === 'production'
            //         ? config.production.successUrl
            //         : config.development.successUrl
            // )
            setMessage('Payment succeeded!')
            clearCart()
            return
        }
        setIsLoading(false)
    }

    const paymentElementOptions = {
        layout: 'tabs',
    }

    return (
        <form className="form-stripe" id="payment-form" onSubmit={handleSubmit}>
            <LinkAuthenticationElement
                id="link-authentication-element"
                onChange={(e) => setEmail(e.target.value)}
            />
            <PaymentElement
                id="payment-element"
                options={paymentElementOptions}
            />
            <button disabled={isLoading || !stripe || !elements} id="submit" className="button-stripe">
                <span id="button-text">
                    {isLoading ? (
                        <div className="spinner" id="spinner">...</div>
                    ) : (
                        'Pay now'
                    )}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    )
}
