import React, { useState } from 'react'
import {
    PaymentElement,
    LinkAuthenticationElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js'
import './checkOutElement.scss'
// import { openNotification } from '../../../utils/helpers'

export default function CheckoutForm({ email }) {
    const stripe = useStripe()
    const elements = useElements()
    const [message, setMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [returnUrl, setReturnUrl] = useState(`${process.env.REACT_APP_BASE_URL}/me/subscription/success`)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            console.log('Stripe / Element has not loaded yet')
            return
        }
        setIsLoading(true)
        const { error, result } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: returnUrl,
            },
        })
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        console.log(result)
        if (error) {
            setReturnUrl(`${process.env.REACT_APP_BASE_URL}/me/subscription`)
            setMessage(error.message)
        } else {
            if (result?.paymentIntent?.status === 'succeeded') {
                setMessage('Payment succeeded!')
                // setPaymentSucess(true)
            } else {
                setMessage('An unexpected error occurred.')
            }
            setReturnUrl(`${process.env.REACT_APP_BASE_URL}/me/subscription/success`)
        }
        setIsLoading(false)
    }
    const paymentElementOptions = {
        layout: 'tabs',
    }

    return (
        <form className="form-stripe" id="payment-form" onSubmit={handleSubmit}>
            <LinkAuthenticationElement
                id="link-authentication-element"
                options={{
                    defaultValues: {
                        email: email,
                    },
                }}
            />
            <PaymentElement
                id="payment-element"
                options={paymentElementOptions}
            />
            <button disabled={isLoading || !stripe || !elements} id="submit" className="button-stripe">
                <span id="button-text">
                    {isLoading ? (
                        <div className="spinner" id="spinner">...</div>
                    ) : (
                        'Pay now'
                    )}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    )
}