import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Checkbox, Dropdown, Menu } from 'antd'
import axios from 'axios';
import { DownOutlined } from '@ant-design/icons';
import { useAuth, useInstructorData } from '../../../hooks'
import './instructorDB.scss'
import { Coursecard } from '../MyCourses/courseCard/index';
import { RequestLoader } from '../../../components/Loading';

export const InstructorDB = () => {
    const {
        state: { data: user },
    } = useAuth()
    // const [isLoading, setIsLoading] = useState()

    const {
        state: { isLoading, courses, instructorID },
        getAllCourses,
        getAllModules,
        getAllLessons,
        getLanguages,
        getCourseCategory,
    } = useInstructorData()
    const [list, setList] = useState(['active'])

    useEffect(() => {
        const source = axios.CancelToken.source()
        const sendRequest = async () => {
            await getLanguages(source.token)
        }
        sendRequest()
        return () => {
            source.cancel('Aborting request in dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const source = axios.CancelToken.source()
        const sendRequest = async () => {
            await getCourseCategory(source.token)
        }
        sendRequest()
        return () => {
            source.cancel('Aborting request in dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const source = axios.CancelToken.source()
        const sendRequest = async () => {
            await getAllCourses(instructorID, source.token)
            await getAllModules(instructorID, source.token)
            await getAllLessons(instructorID, source.token)
        }
        sendRequest()
        return () => {
            source.cancel('Aborting request in dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instructorID])

    const onChange = (e) => {
        const val = e.target.value
        // item has to be checked before been added to list
        if (e.target.checked) {
            // check of value exist in list
            list.includes(val) ? console.log('value exist already') : setList([...list, val])
        } else {
            // remove from the list, utilizing the return array of filter to update prevArry
            const newList = list.filter(lst => lst !== val)
            setList(newList)
        }
    };

    const menu =
        <Menu
            items={[
                { key: 'active', label: (<Checkbox value="active" onChange={onChange}>Active courses</Checkbox>) },
                { key: 'review', label: (<Checkbox value="review" onChange={onChange}>In-review courses</Checkbox>) },
                { key: 'SIC', label: (<Checkbox value="SIC" onChange={onChange}>Still-in-creation courses</Checkbox>) },
                { key: 'retired', label: (<Checkbox value="retired" onChange={onChange}>Retired course</Checkbox>) },
            ]}
        >.
        </Menu>

    return (
        <div id="instructor-dashboard">
            <div id="header">
                <h2 className="title">Hello {user?.firstName}</h2>
            </div>
            <div className="hero">
                <div className="img__container">
                    <div className="hero__overlay">&nbsp;</div>
                    <h3>Course Marketplace</h3>
                </div>
            </div>
            <section className="course__cont">
                <div className="section__heading">
                    <h3>My Courses</h3>
                    <NavLink to="/tutor/apageCreation" id="homeToCreateNewCourse">
                        <Button
                            style={{
                                borderRadius: '10px',
                                paddingInline: '15px'
                            }}
                            type="primary"
                            ghost
                        >
                            Create New Course
                        </Button>
                    </NavLink>
                </div>
                <div className="filter__section">
                    <p>Filter by:</p>
                    <Dropdown
                        id="filter"
                        trigger={['click']}
                        overlay={menu}
                    >
                        <p>Status <DownOutlined /></p>
                    </Dropdown>
                </div>
                <div className="card__cont">
                    {(!isLoading && courses.length < 1) &&
                        <div className="empty_data">
                            <h4>Instructor has no Course</h4>
                        </div>}
                    {(!isLoading && courses) && (courses.map((listCont, idx) => {
                        return <Coursecard key={idx} type={listCont} />
                    }))}
                    {isLoading &&
                        <div className="loader_cont">
                            <RequestLoader />
                        </div>}
                </div>
            </section>
            <section className="course_regulation">
                <h3>Course creation guidelines</h3>
                <div className="content__to__come">&nbsp;</div>
            </section>
        </div>
    )
}