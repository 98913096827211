import { request } from '../utils'

export const getAllPlans = async () => {
    try {
        const url = `/Plan/FindAllPlans`
        const response = await request.get(url)
        return response?.data?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const getPlanById = async (id) => {
    try {
        const url = `/Plan/FindPlanById?id=${id}`
        const response = await request.get(url)
        return response?.data?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const createSubscription = async (values) => {
    try {
        const url = `/Subscription/CreateSubscription`
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findSubscriptionByUserId = async (values) => {
    try {
        const url = `/Subscription/FindSubscriptionByUserId?UserId=${values}`
        const response = await request.get(url)
        return response?.data?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const findPlanByCourseId = async (values) => {
    try {
        const url = `/Plan/FindPlanByCourseId?courseId=${values}`
        const response = await request.get(url)
        return response?.data?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const FindSubscriptionById = async (id) => {
    try {
        const url = `/Subscription/FindSubscriptionById?Id=${id}`
        const response = await request.get(url)
        return response?.data?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const ReadAllSubscription = async () => {
    try {
        const url = `/Subscription/ReadAllSubscriptions`
        const response = await request.get(url)
        return response?.data?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const deleteSubscription = async () => {
    try {
        const url = `/Subscription/DeleteSubscription`
        const response = await request.delete(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

// export const deleteSubscription = async () => {
//     try {
//         const url = `/Subscription/DeleteSubscription`
//         const response = await request.get(url)
//         return response?.data?.data
//     } catch (err) {
//         if (err?.response?.data?.data?.message) {
//             throw new Error(err.response.data.data.message.join('\n\n'))
//         }
//         const error = err?.response?.data?.message || err?.message
//         throw new Error(error)
//     }
// }

export const cancelSubscription = async (values) => {
    try {
        const url = `/Subscription/CancelSubscription`
        const response = await request.post(url, values)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.data || err?.message
        throw new Error(error)
    }
}

export const getSubscriptionHistory = async (page, pageSize) => {
    try {
        const url = `/Subscription/GetSubscriptionHistory?page=${page}&pageSize=${pageSize}`
        const response = await request.get(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}

export const verifyPaystackTransact = async (reference) => {
    try {
        const url = `/Payment/verifyPaystackTransaction?reference=${reference}`
        const response = await request.post(url)
        return response?.data
    } catch (err) {
        if (err?.response?.data?.data?.message) {
            throw new Error(err.response.data.data.message.join('\n\n'))
        }
        const error = err?.response?.data?.message || err?.message
        throw new Error(error)
    }
}