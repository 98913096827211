import {
    AUTH_START,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAIL,
    GET_USER_START,
    GET_USER_SUCCESS,
    GET_USER_FAIL,
    REDIRECT,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    ACTIVE_SSO,
    CLOSE_SSO_MODAL
} from '../../actions/auth/types'

const INIT_STATE = {
    isLoading: false,
    isAppLoading: false,
    isLoggedIn: false,
    data: null,
    error: null,
    redirectTo: null,
    activeSso: false
}

export const authReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case REDIRECT:
            return { redirectTo: action.payload }

        case AUTH_START: {
            return {
                ...state,
                isLoading: true,
            }
        }

        case REGISTER_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                data: action.payload,
                error: null,
            }
        }

        case REGISTER_USER_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                data: null,
                error: action.payload,
            }
        }

        case LOGIN_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                data: action.payload,
                error: null,
            }
        }

        case ACTIVE_SSO: {
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                data: null,
                activeSso: action.payload,
                error: null,
            }
        }

        case CLOSE_SSO_MODAL: {
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                data: null,
                activeSso: action.payload,
                error: null,
            }
        }

        case LOGIN_USER_FAIL: {
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                data: null,
                error: action.payload,
            }
        }
        case GET_USER_START: {
            return {
                ...state,
                isAppLoading: true,
            }
        }

        case GET_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isAppLoading: false,
                isLoggedIn: true,
                data: action.payload,
                error: null,
            }
        }

        case GET_USER_FAIL: {
            return {
                ...state,
                isLoading: false,
                isAppLoading: false,
                isLoggedIn: false,
                data: null,
                error: action.payload,
            }
        }
        case LOGOUT_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                data: null,
                error: null
            }
        }
        case LOGOUT_USER_FAIL: {
            return {
                ...state,
                isLoading: false,
                data: null,
                error: action.payload,
            }
        }

        case DELETE_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false,
                data: null,
                error: null,
                redirectTo: null,
            }
        }

        case DELETE_USER_FAIL: {
            return {
                ...state,
                isLoading: false,
                data: null,
                error: action.payload,
            }
        }

        default:
            return state
    }
}
