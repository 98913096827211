import React from 'react'
// import { Premium } from '../../../components/PremiumCourse'
import Corousel from '../Courses/HeroSection/Carousel'
// import { Cart } from '../../../components/PremiumCourse/cart'
// import CoursesContent from '../Courses/CoursesCards/Courses'
import CoursesContent from './CoursesCards/Courses'
// import SearchBar from '../Courses/SearchBar/SearchBar'
import SearchBar from './SearchBar/SearchBar'
import './styles.scss'

export const PremiumCourses = () => {

    return (
        <div id="premium-page">
            <div className="heading">
                <p>
                    Our courses to assist you in achieving your learning
                    goals
                </p>
            </div>
            <div>
                <Corousel />
                <SearchBar />
            </div>
            <h4>Categories</h4>
            <ul className="lists">
                <li className="list-items">Business</li>
                <li className="list-items">Web Development</li>
                <li className="list-items">Fitness</li>
                <li className="list-items">Design</li>
                <li className="list-items">Marketing</li>
                <li className="list-items">Writing</li>
                <li className="list-items">Speaking</li>
                <li className="list-items">HR Management</li>
            </ul>
            <div>
                <h4 style={{ color: '#3A86FF' }}>One-time Purchase Course</h4>
                <div>
                    Filter by:
                    <span>
                        <select>
                            <option>Course</option>
                            <option>Topic</option>
                            <option>Course</option>
                        </select>
                    </span>
                </div>
            </div>

            <CoursesContent />
        </div>
    )
}