import React from 'react'
import Corousel from './HeroSection/Carousel'
import CoursesContent from './CoursesCards/Courses'
import SearchBar from './SearchBarr/SearchBar'
import './style.scss'

export const Courses = () => {
    return (
        <main id="main-content">
            <Corousel />
            <SearchBar />
            <CoursesContent />
        </main>
    )
}
