import { useState, useEffect } from 'react';
// import axios from 'axios'
// import currencySymbolMap from 'currency-symbol-map'

export const useGeoLocation = () => {
    const [location, setLocation] = useState(null)
    const [userIP, setUserIP] = useState(null)
    const [city, setCity] = useState(null)
    const [region, setRegion] = useState(null)
    const [country, setCountry] = useState(null)
    // localStorage.setItem('currentCountry', country)

    // Only run on mount and unmount
    useEffect(() => {
        const setValues = (json) => {
            setLocation(json)
            setUserIP(json.ip)
            setCity(json.city)
            setRegion(json.region)
            setCountry(json.country)
            localStorage.setItem('currentCountry', json.country)
        }

        fetch('https://ipapi.co/json')
            .then((data) => data.json())
            .then((json) => {
                setValues(json)
            })
            .catch((err) => console.error(err))

        return () => {
            setLocation(null)
            setUserIP(null)
            setCity(null)
            setRegion(null)
            setCountry(null)
            localStorage.removeItem('currentCountry')
        }
    }, [])

    return {
        location,
        setLocation,
        userIP,
        city,
        region,
        country,
    }
};
