import {
    useState,
    useEffect,
    useCallback
} from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Form, Modal } from 'antd'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { PaystackButton } from 'react-paystack'
import { Input, Button } from '../../../../components'
import { useAuth } from '../../../../hooks'
import {
    signUp,
    FindPlanById,
    createIntentCardPayment,
    getAllSupportedCurrency,
    findSubscriptionByUserId
} from '../../../../services'
import { getDeviceId, openNotification, useGeoLocation } from '../../../../utils'
import CheckoutForm from './checkOutElement/checkOutElement'
import CircleBlue from './shared/circle-blue.png'
import CircleGrey from './shared/circle-grey.png'
import Completed from '../../../../assets/icons/Done_loading.svg'
import './payment.scss'
import currencySymbolsData from './currencySymbols'

// import Naira from './shared/naira.png'
// import Pound from './shared/pound.png'
// import { Input } from '../../../../components'
// import CheckoutForm from '../CheckoutStripe/CheckoutForm'

const stripePromise = loadStripe(
    // process.env.REACT_APP_STRIPE_PUBLIC_KEY
    'pk_test_51JaTU7Hi1Iw0XNAQ4gghRCbsaxAFO280HeIEFq8wOGWL5wRUkWjM3DHejEwvc5Q5aCzhmSGxvItWff6bRA7fve1H00gtGuXAdg'
) // FOR STRIPE

const paystack_publicKey = 'pk_test_5c1375018d92a63e0328366a82e7f55f09d464ed' // FOR PAYSTACK

export const Payment = ({ plnId, prevPlanId, offerAccepted }) => {
    const {
        state: { data, isLoading },
        login: loginUser,
    } = useAuth()
    const [form] = Form.useForm()
    const [registerForm] = Form.useForm()
    const [subscriptionPlans, setSubscriptionPlans] = useState([])
    const [clientSecret, setClientSecret] = useState('')
    const [paystackRef, setPaystackRef] = useState('')
    const [planId, setPlanId] = useState('')
    const [paymentDuration, setPaymentDuration] = useState()
    const [checkOutInProgress, setCheckOutInprogress] = useState(false)
    // const [usersCountryData, setUsersCountryData] = useState({
    //     'countryCode': 'NG',
    //     'currencyCode': 'NGN',
    //     'symbol': '&#8358;',
    //     'countryName': 'Nigeria'
    // })
    const [hideLogin, setHideLogin] = useState(true)
    const [hideSignup, setHideSignup] = useState(true)
    const [subscriptionType, setSubscriptionType] = useState('')
    const [courseId, setCourseId] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [expiryDate, setExpiryDate] = useState('')
    const [paymentSuccessModal, setPaymentSuccessModal] = useState(false)
    const history = useHistory();
    const { country } = useGeoLocation()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [symbol, setSymbol] = useState('')
    const memoData = useCallback(() => (currencySymbolsData), [])

    function getReadableDate(data) {
        const option = { month: 'short', day: 'numeric', year: 'numeric' }
        const newDate = new Date(data).toLocaleString('en-US', option)
        return newDate
    }

    // handles dynamic currency for user country location
    useEffect(() => {
        const symbol = () => {
            if (country !== 'NG' && country !== 'ZA' && country !== 'GH' && country !== 'KE') {
                setSymbol('£')
            } else {
                setSymbol('₦')
            }
        }
        symbol()

    }, [country])

    useEffect(() => {
        // Get Course id and setting the subscription type
        const paymentInfo = JSON.parse(localStorage.getItem('SubCourseId'))
        if (paymentInfo !== null && paymentInfo.subscriptionType === 2) {
            setCourseId(paymentInfo.courseId)
            setPaymentDuration(paymentInfo.paymentDuration)
            setSubscriptionType(paymentInfo.subscriptionType)
        } else {
            setSubscriptionType(1)
        }
        // get planId and setState
        if (plnId === undefined) {
            const planIdAddress = window.location.pathname
            const plnId = planIdAddress.split('/').pop()
            setPlanId(plnId)
        } else {
            setPlanId(plnId)
        }
    }, [plnId])

    useEffect(() => {
        const getSubscriptions = async () => {
            try {
                if (planId !== '') {
                    const response = await FindPlanById(planId)
                    if (response.message === 'Success' && paymentDuration) {
                        const amount = ((offerAccepted ? (50 / 100) : 1) * response.data.amount * parseInt(paymentDuration, 10))
                        const newValue = {
                            ...response.data,
                            'name': response.data.name.split(' ')[0],
                            'amount': parseFloat(amount.toFixed(2))
                        }
                        setSubscriptionPlans(newValue)
                    } else {
                        const newValue = {
                            ...response.data,
                            'name': response.data.name.split(' ')[0]
                        }
                        setSubscriptionPlans(newValue)
                    }
                }
            } catch (error) {
                openNotification({
                    type: 'error',
                    title: 'Error',
                    message: error.message,
                })
            }
        }

        getSubscriptions()
    }, [planId, offerAccepted, paymentDuration])

    useEffect(() => {
        const getUserSubscriptions = async () => {
            try {
                if (paymentSuccessModal) {
                    const response = await findSubscriptionByUserId(data.id)
                    if (Object.keys(response.data).length >= 1) {
                        setExpiryDate(getReadableDate(response.data.subscription_end_timestamp))
                    }
                }
            } catch (error) {
                openNotification({
                    type: 'error',
                    title: 'Error',
                    message: error.message,
                })
            }
        }

        getUserSubscriptions()
    }, [paymentSuccessModal, data?.id])

    useEffect(() => {
        const getFIlterForCurrency = async () => {
            const filter = []
            const mainData = memoData()
            try {
                const res = await getAllSupportedCurrency(1, 100)
                for (let y = 0; y < res.data.length; y++) {
                    for (let i = 0; i < mainData.length; i++) {
                        if (res.data[y].code === mainData[i].countryCode) {
                            const newObj = { ...res.data[y], 'symbol': mainData[i].currencySymbol }
                            filter.push(newObj)
                            // setUsersCountryData(stripeSupportedCountries[i])
                        }
                    }
                }
                // if (filter.length > 1) {
                //     for (let i = 0; i < filter.length; i++) {
                //         if (filter[i].code === country) {
                //             setUsersCountryData(filter[i])
                //         }
                //     }
                // }
            } catch (error) {
                console.error(error.message)
            }
        }

        getFIlterForCurrency()
    }, [memoData, country, data?.email, subscriptionPlans.amount])

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [])

    const handleOk = () => {
        setShowModal(false)
        history.push(`/me/payment/${planId}`)
    }

    const handleRegister = async (values) => {
        try {
            const planIdAddress = window.location.pathname
            const res = await signUp({
                ...values,
                deviceId: getDeviceId(),
                countryId: 91,
                userName: values.email,
                userType: 1,
                requestLocation: planIdAddress.slice(1)
            })
            console.log(res)
            setShowModal(true)
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Sign Up',
                message: error.message,
            })
        }
    }

    const options = {
        clientSecret: clientSecret,
        id: 'checkoutForm',
        appearance: {
            theme: 'stripe',
        }
    }

    // Returns gb for all other countries except nigerian, ghana, and south africa. So it returns clientsecret for stripe for gb.
    const cuRR = () => {
        if (['NG', 'ZA', 'GH', 'KE'].includes(country)) {
            return 'ng'
        } else {
            return 'gb'
        }
    }

    const handleCheckout = async () => {
        // Initialize checkout section id
        // use the returned id to launch stripe checkout modal..
        setCheckOutInprogress(true)
        try {
            const checkoutData = {
                productId: parseInt(planId, 10),
                currency: cuRR(),
                email: data?.email,
                subscription: subscriptionType,
                courseId: subscriptionType === 2 ? parseInt(courseId, 10) : 0
            }
            // send a request to the endpoint and retrive the client_secret key
            const res = await createIntentCardPayment(checkoutData)
            if (res.data && res.data.clientSecret !== '') {
                console.log(res.data.clientSecret)
                // store planId in localStorage
                // const subSuccessDta = {
                //     'Subscribed_Plan': planId,
                //     'Subscription_Type': subscriptionType,
                //     'Course_Id': subscriptionType === 2 ? courseId : 0,
                //     'paymentRef': res.data.clientSecret,
                // }

                // Run request for stripe
                if (country !== ('NG' || 'ZA' || 'GH' || 'KE')) {
                    setClientSecret(res.data.clientSecret)
                    const subSuccessDta = {
                        'Subscribed_Plan': planId,
                        'Paystack_PaymentRef': res.data.clientSecret,
                        'Subscription_Type': subscriptionType,
                        'Course_Id': subscriptionType === 2 ? courseId : 0
                    }
                    localStorage.setItem('Subscribed_Data', JSON.stringify(subSuccessDta))
                    // localStorage.setItem('paymentRef', JSON.stringify(res.data.clientSecret))
                } else {
                    setPaystackRef(res.data.clientSecret)
                    // store planId in localStorage
                    localStorage.setItem('Paystack paymentRef', JSON.stringify(res.data.clientSecret))
                    // subSuccessDta['Paystack_PaymentRef'] = res.data.clientSecret
                }
                // localStorage.setItem('Subscribed_Data', JSON.stringify(subSuccessDta))
                // localStorage.removeItem('SubCourseId')
            } else {
                history.push('/me/subscription')
            }
            setCheckOutInprogress(false)
        } catch (err) {
            openNotification({
                title: 'Payment failed',
                message: err.message,
                type: 'error'
            })
            setCheckOutInprogress(false)
        }
    }

    const handleLogin = async (values) => {
        try {
            const response = await loginUser({
                ...values, deviceId: getDeviceId()
            })
            console.log(response)
            history.push(`/me/payment/${planId}`)
        } catch (error) {
            console.log(error)
        }
    }

    const plans = {
        1: 'Free',
        2: 'Monthly',
        11: 'Annual'
    }

    const componentProps = {
        email: data?.email,
        amount: subscriptionPlans.amount * 100,
        metadata: {
            planId: planId,
            paymentType: 'Subscription'
        },
        publicKey: paystack_publicKey,
        text: 'Pay Now',
        onSuccess: () => {
            if (plnId !== undefined) {
                // Open success modal
                setPaymentSuccessModal(true)
            } else {
                const subSuccessDta = {
                    'Subscribed_Plan': planId,
                    'Paystack_PaymentRef': paystackRef,
                    'Subscription_Type': subscriptionType,
                    'Course_Id': subscriptionType === 2 ? courseId : 0
                }
                localStorage.setItem('Subscribed_Data', JSON.stringify(subSuccessDta))
                history.push(`/me/subscription/success`)
                // if (subscriptionType === 1) {
                //     // Redirect to general sub success page
                //     history.push(`/me/subscription/success`)
                // } else {
                //     // REdirect to Single sub success page
                //     history.push(`/me/`)
                // }
            }
            localStorage.removeItem('SubCourseId')
        },
        onClose: () => console.log('Wait! Don\'t leave :('),
    }

    return (
        <div id="payment" className={plnId !== undefined ? 'max_width' : ''}>
            <div className="payment-container">
                <h3>Payment</h3>
            </div>
            <div className="order-container">
                <h3>Order Summary</h3>
            </div>
            <div className="billing-container">
                <h3>Billing Plan</h3>
                <div className="billing-summary-box">
                    <img src={CircleBlue} alt="Circle" />
                    <p className="box-plan">{subscriptionPlans.name} -- {subscriptionType === 2 ? 'Course' : 'General'} Subscription Plan{offerAccepted && '- 50% off'}</p>
                    {/* eslint-disable-next-line */}
                    <p>
                        <span
                            className="currency"
                            // dangerouslySetInnerHTML={{ __html: usersCountryData.symbol }}
                        />
                        {symbol} {subscriptionPlans.amount}
                    </p>
                </div>

                {data ? (
                    <>
                        <div className="user-details">
                            {/* Condition to load stripe or paystack */}
                            {(paystackRef === '') ?
                                <button
                                    id="stripe_btn"
                                    type="submit"
                                    disabled={checkOutInProgress}
                                    onClick={() => {
                                        handleCheckout()
                                    }}
                                >
                                    Checkout
                                </button> : <PaystackButton id="payStack_btn" className="payStack_btn" {...componentProps} />}
                        </div>
                        {/* Triggers stripe checkout form */}
                        {clientSecret && (
                            <Elements stripe={stripePromise} options={options}>
                                <CheckoutForm email={data?.email} clientSecret={clientSecret} />
                            </Elements>
                        )}
                    </>
                ) : (
                    <div className="user-details">
                        <div
                            className="user-box"
                            onClick={() => setHideLogin(!hideLogin)}
                        >
                            <div>
                                <img
                                    src={hideLogin ? CircleGrey : CircleBlue}
                                    alt="Circle"
                                />
                            </div>
                            <div>
                                <p>Already have an account?</p>
                            </div>
                        </div>

                        <Form
                            className={hideLogin ? 'hide' : 'account-details'}
                            name="form"
                            form={form}
                            initialValues={{
                                rememberMe: false,
                            }}
                            onFinish={handleLogin}
                            autoComplete="on"
                        >
                            <div className="form-container">
                                <div className="py-1">
                                    <Input
                                        id="userName"
                                        name="userName"
                                        validationRules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your email!',
                                            },
                                            {
                                                type: 'email',
                                                message:
                                                    'Please input a valid email!',
                                            },
                                        ]}
                                        placeholder="Email"
                                        // disabled={isLoading}
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)}
                                    />
                                </div>

                                <div className="py-1">
                                    <Input.Password
                                        className="pwd"
                                        name="password"
                                        id="password"
                                        validationRules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please input your password!',
                                            },
                                        ]}
                                        placeholder="Password"
                                        // disabled={isLoading}
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)}
                                    />
                                </div>

                                <Input.Checkbox
                                    type="checkbox"
                                    name="rememberMe"
                                    label="Remember me"
                                    id="rememberCheckbox"
                                />

                                <div className="error-1" />

                                <Button
                                    type="submit"
                                    id="signIn"
                                >
                                    Sign in
                                </Button>

                                <div className="forgot-password">
                                    <p id="rememberMe" className="forgot-pwd-link">
                                        <Link to="/account/forgot-password">
                                            Forgot password?
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </Form>

                        <div
                            className={!hideLogin ? 'hide' : 'user-box'}
                            onClick={() => setHideSignup(!hideSignup)}
                        >
                            <div>
                                <img
                                    src={hideSignup ? CircleGrey : CircleBlue}
                                    alt="Circle"
                                />
                            </div>
                            <div>
                                <p>Create an account</p>
                            </div>
                        </div>

                        <Form
                            // className="signup-form"
                            className={
                                hideSignup
                                    ? 'hide'
                                    : 'account-details signup-form'
                            }
                            name="registerForm"
                            form={registerForm}
                            initialValues={{
                                allowedMarketing: false,
                                deviceId: 'deviceId',
                            }}
                            onFinish={handleRegister}
                            autoComplete="on"
                            scrollToFirstError
                        >
                            <div className="pb-2 row">
                                <div className="col-sm-6">
                                    <Input
                                        name="firstName"
                                        id="firstName"
                                        validationRules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please enter your first name',
                                            },
                                        ]}
                                        placeholder="First Name"
                                        disabled={isLoading}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <Input
                                        name="lastName"
                                        id="lastName"
                                        validationRules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please enter your last name',
                                            },
                                        ]}
                                        placeholder="Last Name"
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>

                            <div className="pb-2">
                                <Input
                                    name="email"
                                    id="email"
                                    validationRules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter your email address',
                                        },
                                        {
                                            type: 'email',
                                            message:
                                                'This email address is not valid',
                                        },
                                    ]}
                                    placeholder="Email"
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="pb-2">
                                <Input.Password
                                    className="pwd"
                                    name="password"
                                    id="password"
                                    validationRules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter your password',
                                        },
                                        {
                                            pattern:
                                                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*([^a-zA-Z\d\s])).{9,}$/,
                                            message:
                                                'Password should contain at least 8 characters including 1-uppercase, 1-lowercase, 1-alphanumeric and 1-number',
                                        },
                                    ]}
                                    placeholder="Password"
                                    disabled={isLoading}
                                />
                                {/* <PasswordStrengthMeter
                                passwordInput={values.password}
                            /> */}
                            </div>
                            <div className="pb-2">
                                <Input.Password
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    dependencies={['password']}
                                    validationRules={[
                                        {
                                            required: true,
                                            message:
                                                'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    getFieldValue(
                                                        'password'
                                                    ) === value
                                                ) {
                                                    return Promise.resolve()
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        'The two passwords that you entered do not match!'
                                                    )
                                                )
                                            },
                                        }),
                                    ]}
                                    placeholder="Confirm Password"
                                    disabled={isLoading}
                                />
                            </div>

                            <Input.Checkbox
                                name="terms"
                                label={
                                    <span>
                                        I have read the{' '}
                                        <a href="/t&c">Terms & Conditions </a>
                                    </span>
                                }
                                // validationRules={[
                                //     {
                                //         validator: (_, value) =>
                                //             value
                                //                 ? Promise.resolve()
                                //                 : Promise.reject(
                                //                     new Error(
                                //                         'Should accept agreement'
                                //                     )
                                //                 ),
                                //     },
                                // ]}
                                disabled={isLoading}
                            />

                            <Input.Checkbox
                                name="allowedMarketing"
                                label="Opt in for Marketing emails"
                                disabled={isLoading}
                            />

                            <Button
                                loading={isLoading}
                                type="submit"
                                disabled={isLoading}
                            >
                                Submit
                            </Button>
                        </Form>
                    </div>
                )}
                <Modal
                    title={null}
                    open={showModal}
                    footer={null}
                    closeIcon={null}
                    closable={false}
                >
                    <h2 className="email-header">Email Sent!</h2>
                    <p className="email-text">Please check your email address to verify your Zustech account. Click OK to continue to your account</p>
                    <div className="email-button-con">
                        <button
                            onClick={() => handleOk()}
                            type="button"
                            className="email-button"
                        >
                            OK
                        </button>
                    </div>
                </Modal>
                {/* Successful payment modal */}
                <Modal
                    open={paymentSuccessModal}
                    // open={true}
                    footer={null}
                    // onCancel={() => { setPaymentSuccessModal(false) }}
                    // closable={false}
                    id="paymentSuccessModal"
                    className="paymentSuccessModal"
                    centered
                    width={600}
                    style={{
                        fontWeight: '600'
                    }}
                >
                    <h2 className="title">Congratulation!!</h2>
                    <div className="img_cont">
                        <img src={Completed} alt="done" />
                    </div>
                    <p>You have successfully changed from an {plans[prevPlanId]} plan to a {plans[plnId]} plan.</p>
                    <p
                        style={{
                            marginBlock: '.6em'
                        }}
                    >New expiry date is
                    </p>
                    <p
                        className="date"
                        style={{
                            color: '#3a86ff'
                        }}
                    >{expiryDate}
                    </p>
                    <p
                        style={{
                            marginBlock: '.6em'
                        }}
                    >Continue your learning experience
                    </p>
                    <Button
                        type="primary"
                        onClick={() => {
                            history.push('/me/courses')
                        }}
                        style={{
                            width: '30%'
                        }}
                    >Continue
                    </Button>
                </Modal>
            </div>
        </div>
    )
}