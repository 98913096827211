import { useEffect, useState, Fragment } from 'react'
import './style.scss'
import { loadStripe } from '@stripe/stripe-js'
import {
    useStripe,
    Elements
} from '@stripe/react-stripe-js'
// import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks'
import {
    getPlanById,
    getSubscriptionHistory,
    verifyPaystackTransact,
} from '../../../services'
import { useGeoLocation } from '../../../utils'
import { MultiSubSuccess } from './multiSubSuccess'
import { SingleSubSuccess } from './singleSubSuccess'
import { RequestLoader } from '../../../components/Loading'

const stripePromise = loadStripe(
    // process.env.REACT_APP_STRIPE_PUBLIC_KEY
    'pk_test_51JaTU7Hi1Iw0XNAQ4gghRCbsaxAFO280HeIEFq8wOGWL5wRUkWjM3DHejEwvc5Q5aCzhmSGxvItWff6bRA7fve1H00gtGuXAdg'
) // FOR STRIPE

function SuccessPageContent({ country, clientSecret }) {
    const {
        state: { data }
    } = useAuth()
    const [planAmt, setPlanAmt] = useState()
    const [planName, setPlanName] = useState()
    const [renewalDate, setRenewalDate] = useState()
    const [startDate, setStartDate] = useState()
    const [subType, setSubType] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [symbol, setSymbol] = useState()
    const stripe = useStripe()
    const [paymentSuccess, setPaymentSuccess] = useState('')
    const [message, setMessage] = useState('')
    const [courseId, setCourseId] = useState()

    useEffect(() => {
        const symbol = () => {
            if (['NG', 'ZA', 'GH', 'KE'].includes(country)) {
                setSymbol('₦')
            } else {
                setSymbol('£')
            }
        }
        symbol()
    }, [country])

    function getReadableDate(data) {
        const option = { month: 'short', day: 'numeric', year: 'numeric' }
        const newDate = new Date(data).toLocaleString('en-US', option)
        return newDate
    }

    useEffect(() => {
        const Subscribed_Data = JSON.parse(localStorage.getItem('Subscribed_Data'))
        setCourseId(parseInt(Subscribed_Data.Course_Id, 10))
        switch (parseInt(Subscribed_Data.Subscription_Type, 10)) {
            case 1:
                setSubType('general')
                break;
            case 2:
                setSubType('course')
                break;
            default:
                break;
        }

        if (country !== 'NG' && country !== 'ZA' && country !== 'GH' && country !== 'KE') {
            if (!stripe) {
                console.log('Stripe has not loaded yet')
                return
            }
            if (!clientSecret) {
                console.log('payment intent client secret is needed to action checkout')
                return
            }
            stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
                setPaymentSuccess(true)
                switch (paymentIntent.status) {
                    case 'succeeded':
                        console.log(paymentIntent.status)
                        setPaymentSuccess(true);
                        setMessage('Your payment was successful')
                        break
                    case 'processing':
                        setPaymentSuccess(false);
                        setMessage('Your payment is processing.')
                        break
                    case 'requires_payment_method':
                        setPaymentSuccess(false);
                        setMessage(
                            'Your payment was not successful, please try again.'
                        )
                        break
                    default:
                        setMessage('Something went wrong.')
                        setPaymentSuccess(false);
                        break
                }
                setIsLoading(false)
            })
        }
    }, [stripe, country, clientSecret])

    useEffect(() => {
        // Wanna get planId from localStore and fetch neccessary data with the id
        const getPlanInfo = async () => {
            try {
                const Subscribed_Data = JSON.parse(localStorage.getItem('Subscribed_Data'))
                const planId = parseInt(Subscribed_Data.Subscribed_Plan, 10)
                const subInfo = await getSubscriptionHistory(1, 100)
                if (planId !== null) {
                    const plan = await getPlanById(planId)
                    setPlanAmt(plan?.amount)
                    setPlanName(plan?.name)
                    subInfo.data.forEach((plan) => {
                        if (plan.planId === planId) {
                            setStartDate(getReadableDate(plan.subscription_start_timestamp))
                            setRenewalDate(getReadableDate(plan.subscription_end_timestamp))
                        }
                    })
                } else {
                    setPlanName(0)
                    return
                }
            } catch (err) {
                console.error(err.message);
            }
        }

        getPlanInfo()
    }, [])

    return (
        <Fragment>
            {isLoading &&
                <div className="subscription_cont loader_cont">
                    <RequestLoader />
                </div>}
            {(!isLoading && subType === 'general') &&
                <MultiSubSuccess
                    paymentSuccess={paymentSuccess}
                    startDate={startDate}
                    planName={planName}
                    renewalDate={renewalDate}
                    symbol={symbol}
                    planAmt={planAmt}
                    data={data}
                    message={message}
                />}
            {!isLoading && subType === 'course' &&
                <SingleSubSuccess
                    paymentSuccess={paymentSuccess}
                    startDate={startDate}
                    data={data}
                    message={message}
                    courseId={courseId}
                />}
        </Fragment>
    )
}

function PaystackSuccessPage({ country }) {
    const {
        state: { data }
    } = useAuth()
    const [planAmt, setPlanAmt] = useState()
    const [planName, setPlanName] = useState()
    const [renewalDate, setRenewalDate] = useState()
    const [startDate, setStartDate] = useState()
    const [subType, setSubType] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [symbol, setSymbol] = useState()
    const [paymentSuccess, setPaymentSuccess] = useState('')
    const [message, setMessage] = useState('')
    const [courseId, setCourseId] = useState()

    useEffect(() => {
        const symbol = () => {
            if (['NG', 'ZA', 'GH', 'KE'].includes(country)) {
                setSymbol('₦')
            } else {
                setSymbol('£')
            }
        }
        symbol()
    }, [country])

    function getReadableDate(data) {
        const option = { month: 'short', day: 'numeric', year: 'numeric' }
        const newDate = new Date(data).toLocaleString('en-US', option)
        return newDate
    }

    useEffect(() => {
        const Subscribed_Data = JSON.parse(localStorage.getItem('Subscribed_Data'))
        setCourseId(parseInt(Subscribed_Data.Course_Id, 10))
        switch (parseInt(Subscribed_Data.Subscription_Type, 10)) {
            case 1:
                setSubType('general')
                break;
            case 2:
                setSubType('course')
                break;
            default:
                break;
        }
        const getPaymentStatus = async () => {
            try {
                const res = await verifyPaystackTransact(Subscribed_Data.Paystack_PaymentRef)
                if (res.status === 1) {
                    setPaymentSuccess(true)
                    setMessage('Your payment was successful')
                } else {
                    setMessage('Your payment is processing.')
                }
            } catch (err) {
                console.error(err.message)
            }
        }

        getPaymentStatus()
    }, [])

    useEffect(() => {
        // Wanna get planId from localStore and fetch neccessary data with the id
        const getPlanInfo = async () => {
            try {
                const Subscribed_Data = JSON.parse(localStorage.getItem('Subscribed_Data'))
                const planId = parseInt(Subscribed_Data.Subscribed_Plan, 10)
                const subInfo = await getSubscriptionHistory(1, 100)
                if (planId !== null) {
                    const plan = await getPlanById(planId)
                    setPlanAmt(plan?.amount)
                    setPlanName(plan?.name)
                    subInfo.data.forEach((plan) => {
                        if (plan.planId === planId) {
                            setStartDate(getReadableDate(plan.subscription_start_timestamp))
                            setRenewalDate(getReadableDate(plan.subscription_end_timestamp))
                        }
                    })
                } else {
                    setPlanName(0)
                    return
                }
                setIsLoading(false)
            } catch (err) {
                console.error(err.message);
            }
        }

        getPlanInfo()
    }, [])

    return (
        <Fragment>
            {isLoading &&
                <div className="subscription_cont loader_cont">
                    <RequestLoader />
                </div>}
            {(!isLoading && subType === 'general') &&
                <MultiSubSuccess
                    paymentSuccess={paymentSuccess}
                    startDate={startDate}
                    planName={planName}
                    renewalDate={renewalDate}
                    symbol={symbol}
                    planAmt={planAmt}
                    data={data}
                    message={message}
                />}
            {!isLoading && subType === 'course' &&
                <SingleSubSuccess
                    paymentSuccess={paymentSuccess}
                    startDate={startDate}
                    data={data}
                    message={message}
                    courseId={courseId}
                />}
        </Fragment>
    )
}

export function SuccessPage() {
    const { country } = useGeoLocation()
    const [options, setOptions] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [paymentGateway, setPaymentGateway] = useState('')
    useEffect(() => {
        if (country) {
            if (country !== ('NG' || 'ZA' || 'GH')) {
                const clientSecret = new URLSearchParams(window.location.search).get(
                    'payment_intent_client_secret'
                )
                setOptions({
                    clientSecret: clientSecret,
                    appearance: {
                        theme: 'stripe',
                    }
                })
                setPaymentGateway('stripe')
            } else {
                const Subscribed_Data = JSON.parse(localStorage.getItem('Subscribed_Data'))
                setOptions({
                    clientSecret: Subscribed_Data.Paystack_PaymentRef,
                })
                setPaymentGateway('paystack')
            }
            setIsLoading(false)
        } else {
            setIsLoading(true)
        }
    }, [country])

    return (
        <>
            {(!isLoading && paymentGateway === 'stripe') &&
                <Elements stripe={stripePromise} options={options}>
                    <SuccessPageContent country={country} clientSecret={options.clientSecret} />
                </Elements>}
            {(!isLoading && paymentGateway === 'paystack') &&
                <PaystackSuccessPage country={country} />}
            {isLoading &&
                <div className="subscription_cont loader_cont">
                    <RequestLoader />
                </div>}
        </>
    )
}