import React, { useState } from 'react'
import './Notification.scss'
import { Form } from 'antd'
import marketing_icon from '../../../../assets/icons/marketing_notification.svg'
// import learning_icon from '../../../../assets/icons/learning_notification.svg'
// import community_icon from '../../../../assets/icons/Community_notification.svg'
import { Input, Button } from '../../../../components'
import {
    updateUserMarketingNotifications,
    // updateUserCommunityNotification,
} from '../../../../services'
import { useAuth } from '../../../../hooks'
import { openNotification } from '../../../../utils'

export const Notification = () => {
    // const [isunhidden, setisunHidden] = useState(false)
    const {
        state: { data: user },
    } = useAuth()
    const [loading, setLoading] = useState(false)

    // Uncomment for community notification hidden code

    // function toggle() {
    //     setisunHidden((wasOpened) => !wasOpened)
    // }

    // const updateCommunityNotification = (values) => {
    //     console.log('Community Notifications', values)
    // }

    // Uncomment for community notification api link

    // const updateCommunityNotification = async (values) => {
    //     setLoading(true)
    //     try {
    //         const response = await updateUserCommunityNotification(values)
    //         openNotification({
    //             type: 'success',
    //             title: 'Community Notification',
    //             message: response.message,
    //         })
    //     } catch (error) {
    //         openNotification({
    //             type: 'error',
    //             title: 'Community Notification',
    //             message: error.message,
    //         })
    //     }
    //     setLoading(false)
    // }

    // link to marketing email api

    const updateMarketingNotification = async (values) => {
        setLoading(true)

        const filteredValues = Object.fromEntries(
            Object.entries(values).filter(
                // eslint-disable-next-line no-unused-vars
                ([_, value]) => typeof value !== 'undefined'
            )
        )

        try {
            const response = await updateUserMarketingNotifications({
                ...user.marketingNotification,
                ...filteredValues,
            })
            openNotification({
                type: 'success',
                title: 'Marketing Notification',
                message: response.message,
            })
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Marketing Notification',
                message: error.message,
            })
        }
        setLoading(false)
    }
    return (
        <div id="notification-settings">
            <div className="notification">
                <h4 className="title">Notifications</h4>

                <div className="box">
                    <div className="field">
                        <img
                            src={marketing_icon}
                            alt="Marketing"
                            className="image"
                        />
                        <div className="text">
                            <div className="header">Marketing</div>
                            <div className="description">
                                <span>
                                    emails on are best deals, new courses &
                                    helpful Resorces
                                </span>
                            </div>
                        </div>
                    </div>

                    <Form
                        className="list"
                        onFinish={updateMarketingNotification}
                    >
                        {/* default checked checks based on true or false, true = checked */}
                        <div>
                            <Input.Checkbox
                                disabled={loading}
                                name="deals_Sales"
                                label="Updates on deals & sales on zustech"
                                defaultChecked={
                                    user?.marketingNotification?.deals_Sales
                                }
                            />
                        </div>

                        <div>
                            <Input.Checkbox
                                disabled={loading}
                                name="courseRecommendation"
                                label="Updates on recommended courses for you"
                                defaultChecked={
                                    user?.marketingNotification
                                        ?.courseRecommendation
                                }
                            />
                        </div>

                        <div>
                            <Input.Checkbox
                                disabled={loading}
                                label="Updates on are trending courses"
                                name="trendingCourse"
                                defaultChecked={
                                    user?.marketingNotification?.trendingCourse
                                }
                            />
                        </div>

                        <div>
                            <Input.Checkbox
                                disabled={loading}
                                label="Updates with helpful resources to give advice on learning, finding a career, cv making and more"
                                name="resourceRecommendation"
                                defaultChecked={
                                    user?.marketingNotification
                                        ?.resourceRecommendation
                                }
                            />
                        </div>

                        <div>
                            {/* updates the marketing emails based on Ticked boxes */}
                            <Button type="submit" loading={loading}>
                                Update Marketing Notifications
                            </Button>
                        </div>
                    </Form>
                </div>

                {/* <div className="learning">
                    <div className="field">
                        <img
                            src={learning_icon}
                            alt="learning"
                            className="image"
                        />
                        <div className="text">
                            <div className="header"> Learning Progress </div>

                            <div className="description">
                                notify me with reminders for deadlines & more to
                                help keep me on track of my progress
                            </div>
                        </div>
                    </div>

                    <div className="list">
                        <Input.Checkbox
                            name="dealUpdate"
                            label="Updates on deadlines & progress"
                            checked={checked}
                            onClick={checked}
                        />
                    </div>
                </div> */}

                {/* <div className="box">
                    <div className="field">
                        <img
                            src={community_icon}
                            alt="community"
                            className="image"
                        />
                        <div className="text">
                            <div className="header"> Community </div>

                            <div className="description">
                                <span>
                                    notify for new messages and forums you are
                                    part of
                                </span>
                            </div>
                        </div>
                    </div>
                    <Form
                        className="list"
                        onFinish={updateCommunityNotification}

                        // initialValues={user.communityNotification}
                    >
                        <div>
                            <Input.Checkbox
                                disabled={loading}
                                name="forumUpdates"
                                label=" Updates from forums I’m in "
                            />
                            <span className="hide" onClick={toggle}>
                                Edit individual classes (0)
                            </span>
                        </div>

                        {isunhidden && (
                            <div className="hidden-list">
                                <div>
                                    <Input.Checkbox
                                        disabled={loading}
                                        name="class1"
                                        label="Learn Python Programming Masterclass"
                                    />
                                    <span className="courseInstructors">
                                        John doe
                                    </span>
                                </div>

                                <div>
                                    <Input.Checkbox
                                        disabled={loading}
                                        name="class2"
                                        label="Front End Development  "
                                    />
                                    <span className="courseInstructors">
                                        John doe
                                    </span>
                                </div>

                                <div>
                                    <Input.Checkbox
                                        disabled={loading}
                                        name="class3"
                                        label=" UX design fundamentals "
                                    />
                                    <span className="courseInstructors">
                                        John doe
                                    </span>
                                </div>
                            </div>
                        )}
                        <br />
                        <div>
                            <Input.Checkbox
                                disabled={loading}
                                name="newMessages"
                                label=" Updates for new messages  "
                            />
                        </div>

                        <div>
                            <Input.Checkbox
                                disabled={loading}
                                name="classUpdates"
                                label=" Updates for a new class from someone you follow "
                            />
                        </div>

                        <div>
                            <Button type="submit" loading={loading}>
                                Update Community Notifications
                            </Button>
                        </div>
                    </Form>
                </div> */}
            </div>
        </div>
    )
}
