import React, { useState, useEffect, useContext } from 'react'
// import { NavLink } from 'react-router-dom'
import { useAuth } from '../../../../hooks'
import './successPage.scss'
import { CartContext } from '../../../../context/Cart/cartContext'

export function SuccessPage() {
    const {
        state: { data: user },
    } = useAuth()
    const [courseItems, setCourseItems] = useState()
    const { cartItems, premSuccessItems } = useContext(CartContext)
    console.log(cartItems)
    console.log(premSuccessItems)

    // copy cartItems to courseItems when cartItems changes
    useEffect(() => {
        // setCourseItems(premSuccessItems)
        // const newArray = Object.values(cartItems)
        // const copiedArray = JSON.parse(JSON.stringify(newArray))
        // const copiedArray = cartItems.map((item) => ({ ...item }))
        // console.log(copiedArray)
        setCourseItems(premSuccessItems)
    }, [premSuccessItems])
    // const copiedArray = JSON.parse(JSON.stringify(cartItems))
    // setCourseItems(copiedArray)

    console.log(courseItems)

    return (
        <main className="wrapper">
            <div className="success-container">
                <section className="top--section">
                    <div className="left--content">
                        <h4>{new Date().toLocaleDateString()}</h4>
                        <div className="left-info">
                            <h4>Premium</h4>
                            <p className="info-1">
                                This is the premium course: <br />
                                Your will have full access to this course at all
                                times.
                            </p>
                            <p className="info-2">
                                Click the button below to get started
                            </p>
                        </div>
                    </div>
                    <div className="right--content">
                        <h4>Thank You For Your Purchase!</h4>
                        <p>
                            Hello,{' '}
                            <strong>
                                {user.firstName + ' ' + user.lastName}
                            </strong>
                            , You now have access to:
                            {courseItems && courseItems.length > 0 ? (
                                courseItems.map((item) => {
                                    return (
                                        <div className="course--list" key={item.id}>{item.name} {', '}</div>
                                    )
                                })
                            ) : (
                                <div>No courses available.</div>
                            )}
                        </p>
                        <p>
                            We hope you find your time here with us to be fun
                            and safe. If there's anything we can do to make the
                            experience better. Please let us know!
                        </p>
                        <span className="visit--info">
                            Please visit our support page if you are
                            experiencing any issues or have any questions!
                        </span>
                        <p className="salutation">
                            Thanks again, <br />
                            Zustech Team!
                        </p>
                    </div>
                </section>
                <section className="bottom--section">
                    {courseItems && courseItems.length > 0 ? (
                        courseItems.map((item) => {
                            return (
                                <div key={item.id} className="bottom--main">
                                    <p className="bottom-course-info">
                                        {item.name}
                                    </p>
                                    <button
                                        className="start-learning-btn"
                                        disabled
                                    >
                                        Start Learning
                                    </button>
                                </div>
                            )
                        })
                    ) : null}
                </section>
            </div>
        </main>
    )
}