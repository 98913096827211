import React from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { Menu, Checkbox, Dropdown } from 'antd'

const FilterSection = ({ enrolStatus, handleCheckboxChange }) => {

    // const [enrolStatus, setEnrolStatus] = useState({
    //     notStarted: false,
    //     inProgress: false,
    //     completed: false,
    // })
    // const handleCheckboxChange = (key) => {
    //     setEnrolStatus((prevStatus) => ({
    //         ...prevStatus,
    //         notStarted: key === 'notStarted',
    //         inProgress: key === 'inProgress',
    //         completed: key === 'completed',
    //     }))
    // }

    const menu1 = (
        <Menu
            items={[
                {
                    key: 'Not-started',
                    label: (
                        <Checkbox
                            checked={enrolStatus.notStarted}
                            onChange={() => { handleCheckboxChange('notStarted') }}
                        >
                            Not started
                        </Checkbox>
                    ),
                },
                {
                    key: 'In-progress',
                    label: (
                        <Checkbox
                            checked={enrolStatus.inProgress}
                            onChange={() => { handleCheckboxChange('inProgress') }}
                        >
                            In-progress
                        </Checkbox>
                    ),
                },
                {
                    key: 'Completed',
                    label: (
                        <Checkbox
                            checked={enrolStatus.completed}
                            onChange={() => { handleCheckboxChange('completed') }}
                        >
                            Completed
                        </Checkbox>
                    ),
                },
            ]}
        />
    )

    const menu2 = (
        <Menu
            items={[
                {
                    key: 'one',
                    label: (
                        <Checkbox
                            checked={false}
                            onChange={(e) => e.target.checked}
                        >
                            UI/UX Design
                        </Checkbox>
                    ),
                },
                {
                    key: 'two',
                    label: (
                        <Checkbox
                            checked={false}
                            onChange={(e) => e.target.checked}
                        >
                            Business Analysis
                        </Checkbox>
                    ),
                },
                {
                    key: 'three',
                    label: (
                        <Checkbox
                            checked={false}
                            onChange={(e) => e.target.checked}
                        >
                            Programming
                        </Checkbox>
                    ),
                },
            ]}
        />
    )

    return (
        <div>
            <div className="filter-cont">
                <p> Filter by: </p>
                <Dropdown id="filter" trigger={['click']} overlay={menu1}>
                    <p>
                        Status <FiChevronDown />
                    </p>
                </Dropdown>
                <Dropdown id="filter" trigger={['click']} overlay={menu2}>
                    <p>
                        Category <FiChevronDown />
                    </p>
                </Dropdown>
            </div>
        </div>
    )
}

export default FilterSection
