import React, { useState, useEffect } from 'react'
// import { useAuth } from '../../../../hooks'
import { NoEnrolled } from '../NoEnrolled'
import { ActiveEnrolled } from '../ActiveEnrolled'
import { getEnrolledCourses } from '../../../../services'
import LoadingSpinner from  '../ActiveEnrolled/component/LoadingSpinner'
import FilterSection from './FilterSection'
import './mainEnrolled.scss'

export const MainEnrolledPage = () => {
    // user id needed as arg to fetch the progress of course
    // const {
    //     state: { data: user },
    // } = useAuth()
    // const [originalEnrolledData, setOriginalEnrolledData] = useState([])
    const [enrolledData, setEnrolledData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [enrolStatus, setEnrolStatus] = useState({
        notStarted: false,
        inProgress: false,
        completed: false,
    }) // Above are the States for filtering the status of the enrol course.

    // Below is the handle change function for the filter checkbox, it ensures only 1 is selected.
    const handleCheckboxChange = (key) => {
        setEnrolStatus((prevStatus) => ({
            ...prevStatus,
            // [key]: prevStatus[key],
            notStarted: key === 'notStarted',
            inProgress: key === 'inProgress',
            completed: key === 'completed',
        }))
    }

    // Fetch's the Enrolled Courses
    useEffect(() => {
        setIsLoading(true)
        const GetEnrolCourse = async () => {
            try {
                const res = await getEnrolledCourses(1, 60)
                // setOriginalEnrolledData(res?.data)
                setEnrolledData(res?.data)
            } catch (err) {
                console.error(err.message)
            }
            setIsLoading(false)
        }
        GetEnrolCourse()
    }, [])

    // Loading spinner displays for delayed response or when component is loading
    if (isLoading) {
        return <LoadingSpinner />
    }
    // Displays the page for empty enrolled course if there's no active enrolled course
    if (enrolledData.length <= 0) {
        return <NoEnrolled />
    }

    return (
        <div>
            <h4
                className="heading--"
                // style={{ color: '#0837E5', fontSize: '1.3rem' }}
            >
                Enrolled Courses
            </h4>
            <FilterSection
                enrolStatus={enrolStatus}
                handleCheckboxChange={handleCheckboxChange}
            />

            {enrolledData.map((course) => {
                return (
                    <ActiveEnrolled
                        key={course.id}
                        id={course.id}
                        course={course}
                        handleCheckboxChange={handleCheckboxChange}
                        enrolStatus={enrolStatus}
                    />
                )
            })}
        </div>
    )
}

// {
//     enrolledData.length >= 1 ? (
//         <ActiveEnrolled
//             user={user}
//             // isLoading={isLoading}
//             enrolledData={enrolledData}
//             handleCheckboxChange={handleCheckboxChange}
//             enrolStatus={enrolStatus}
//             setEnrolStatus={setEnrolStatus}
//         />
//     ) : (
//         <NoEnrolled />
//     )
// }

// Filters the enrolledData and returns courses as per what status was clicked (either inProgress or completed etc.)
// useEffect(() => {
//     const filteredData = originalEnrolledData.filter((course) => {
//         if (enrolStatus.notStarted) {
//             return course.courseCompletionStatus === 0
//         } else if (enrolStatus.inProgress) {
//             return (
//                 course.courseCompletionStatus === 0 ||
//                 (course.courseCompletionStatus > 0 &&
//                     course.courseCompletionStatus < 100)
//             )
//         } else if (enrolStatus.completed) {
//             // Do nothing if there are no completed courses
//             if (
//                 originalEnrolledData.every(
//                     (course) => course.courseCompletionStatus === 0
//                 )
//             ) {
//                 return false
//             }
//             return course.courseCompletionStatus === 100
//         }
//         return true
//     })

//     setEnrolledData(filteredData)
// }, [])

// (REMINDER: I keep getting a multi rendering when adding enrolledData to dependency, and i dont know why)