import {
    GET_INSTRUCTOR_ID,
    FETCH_DATA,
    FETCH_FAILED,
    REQUEST_PASSED,
    GET_INSTRUCTOR_LESSON,
    GET_INSTRUCTOR_MODULE,
    GET_INSTRUCTOR_COURSE,
    GET_COURSE_CATEGORIES,
    GET_LANGUAGES,
    GET_INSTRUCTOR_APPLICATION_STATUS
} from '../../actions/instrucProd/types'

const INIT_STATE = {
    isLoading: false,
    isAppLoading: false,
    isInstructor: '',
    applicationStatus: '',
    instructorID: '',
    lessons: [],
    modules: [],
    coursesCategories: [],
    languages: [],
    courses: [],
    error: null,
}

export const instructorReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_DATA:
            return { ...state, isLoading: true }
        case FETCH_FAILED:
            return {
                ...state,
                isLoading: false,
                // isInstructor: false,
                error: action.payload
            }
        case REQUEST_PASSED:
            return {
                ...state,
                isLoading: false,
            }
        case GET_INSTRUCTOR_ID:
            return {
                ...state,
                instructorID: action.payload,
                error: null
            }
        case GET_INSTRUCTOR_APPLICATION_STATUS:
            return {
                ...state,
                isInstructor: action.payload === 1 ? true : !true,
                applicationStatus: action.payload,
                error: null
            }
        case GET_INSTRUCTOR_LESSON:
            return {
                ...state,
                lessons: action.payload,
                error: null
            }
        case GET_INSTRUCTOR_MODULE:
            return {
                ...state,
                modules: action.payload,
                error: null
            }
        case GET_INSTRUCTOR_COURSE:
            return {
                ...state,
                courses: action.payload,
                error: null
            }
        case GET_COURSE_CATEGORIES:
            return {
                ...state,
                coursesCategories: action.payload,
                error: null
            }
        case GET_LANGUAGES:
            return {
                ...state,
                languages: action.payload,
                error: null
            }
        default:
            return state

    }
}