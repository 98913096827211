import React, { useState, useEffect, Fragment } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Step1Form from './Step1Form'
import Step2Form from './Step2Form'
import { InstructorHandbook } from './InstructorHandbook'
import { TermsConditionModal } from './TermsConditionModal'
import IconSteps from './assets/Group 137.png'
import './style.scss'
import {
    createInstructor,
    findInstructorByUserId,
} from '../../../services'
import { LoadingSpinner2 } from '../../../components'
import { useAuth } from '../../../hooks'
import { openNotification } from '../../../utils'

const InstructorOnboarding = () => {
    const {
        state: { data },
    } = useAuth()
    const { handleSubmit, control, formState } = useForm()
    const history = useHistory()

    // Redirect to main dashboard if user is already an instructor
    useEffect(() => {
        const getStatus = async () => {
            try {
                const userId = data.id
                console.log(userId)
                const response = await findInstructorByUserId(userId)
                console.log(response)
                if (response.data.instructorApplicationStatus === 1) {
                    history.push('/me')
                } else {
                    openNotification({
                        type: 'success',
                        title: 'Instructor Application',
                        message: 'Please complete your application and submit',
                    })
                }
            } catch (error) {
                console.log(error.message)
            }
        }
        getStatus()
    // eslint-disable-next-line
    }, [data, history])

    const initialFormValues = {
        contactNumber: '',
        country: '',
        instructorBio: '',
        jobTitle: '',
        proficiency: '',
    }
    const [formValues, setFormValues] = useState(initialFormValues)
    // const [isStep1, setIsStep1] = useState(true)
    const [currentStep, setCurrentStep] = useState(1)
    const [isLoading, setIsLoading] = useState(false)
    const [openTermsModal, setOpenTermsModal] = useState(false)
    const [isTermsAgreed, setIsTermsAgreed] = useState(false)

    const isFormFilled = Object.values(formValues).every(
        value => value !== ''
    )
    // console.log(formValues)

    const handleChange = (e) => {
        const { name, value, type } = e.target
        setFormValues((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? `checked` : value,
        }))
    }

    const handleNext = () => {
        if (isFormFilled) {
            setCurrentStep(currentStep + 1);
        } else {
            // Handle the case where the form is not filled
            setCurrentStep(1)
        }
    }

    const handlePrev = () => {
        // Ensure the current step is greater than 1 before going back
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1)
        }
    }

    const TranslateProficiency = (value) => {
        switch (value) {
            case 'First time':
                return 1
            case 'Very poor':
                return 2
            case 'Average':
                return 3
            case 'Expert':
                return 4
            default:
                return 1
        }
    }

    const handleSubmitFinish = async (values) => {
        if (isTermsAgreed) {
            setOpenTermsModal(false)
        } else {
            setOpenTermsModal(true)
        }
        if (isFormFilled && isTermsAgreed) {
            try {
                setIsLoading(true)
                // Perform form submission logic
                const userId = data.id
                const email = data.email
                await createInstructor({
                    ...values,
                    userId: userId,
                    biography: formValues.instructorBio,
                    phoneNumber: formValues.contactNumber,
                    jobTitle: formValues.jobTitle,
                    country: formValues.country,
                    teachingProficiency: TranslateProficiency(
                        formValues.proficiency
                    ),
                    email: email,
                })
                history.push('/me/instructor-onboard-success')
                // Clear data from local storage when the form is submitted
                // localStorage.removeItem('formData')
                setFormValues(initialFormValues)
            } catch (error) {
                console.log(error.message)
                openNotification({
                    type: 'error',
                    title: 'Instructor Onboarding',
                    message: error.message,
                })
            }
        } else {
            console.log('Please agree to the terms and conditions before submitting.')
        }
        setIsLoading(false)
    }

    if (isLoading) return <LoadingSpinner2 />

    return (
        <Fragment>
            {openTermsModal === true ? (
                <TermsConditionModal
                    openTermsModal={openTermsModal}
                    setOpenTermsModal={setOpenTermsModal}
                    setIsTermsAgreed={setIsTermsAgreed}
                    // handleTermsAgreement={handleTermsAgreement}
                />
            ) : null}
            <InstructorHandbook />
            <div
                className="instruct--onboard--container"
                id="instructor--onboarding"
            >
                <img
                    src={IconSteps}
                    className="icon--steps"
                    alt="applic-steps"
                />
                <form
                    onSubmit={handleSubmit(handleSubmitFinish)}
                    className="main--form--wrapper"
                    id="main--form--wrapper"
                >
                    {currentStep === 1 ? (
                        <Step1Form
                            formValues={formValues}
                            setFormValues={setFormValues}
                            isFormFilled={isFormFilled}
                            handleNext={handleNext}
                            handleChange={handleChange}
                        />
                    ) : null}

                    {currentStep === 2 ? (
                        <Step2Form
                            formValues={formValues}
                            setFormValues={setFormValues}
                            handleSubmitFinish={handleSubmitFinish}
                            handlePrev={handlePrev}
                            handleChange={handleChange}
                            setOpenTermsModal={setOpenTermsModal}
                            isTermsAgreed={isTermsAgreed}
                            Controller={Controller}
                            control={control}
                            formState={formState}
                        />
                    ) : null}
                </form>
            </div>
        </Fragment>
    )
}
export default InstructorOnboarding