import React, { useEffect, useState } from 'react'
// import { Link } from 'react-router-dom'
import { Button, Modal } from 'antd'
import Euro from '../../../../assets/icons/euro.svg'
import Students from '../../../../assets/icons/stdEnroll.svg'
import Star from '../../../../assets/icons/Stars.svg'
// Style sheet is in parent folder ../courseCard.scss

export function ActiveCourse({ type, thumbnail, CTA }) {
    const [openModal, setOpenModal] = useState(false)
    const [coursePicture, setCoursePicture] = useState('')
    useEffect(() => {
        if (type.coursePicture !== null && type.coursePicture !== '' && type.coursePicture !== undefined) {
            setCoursePicture(type.coursePicture)
        } else {
            setCoursePicture(thumbnail)
        }

        // eslint-disable-next-line
    }, [type.coursePicture])

    return (
        <div className="course__card">
            <div className="course__thumbnail">
                <img src={coursePicture} alt="thumbnail" />
            </div>
            <div className="course__details">
                <h3 title={type.name}>{type.name}</h3>
                <div>
                    <p>Published 01/02/23</p>
                    <button>Active Course</button>
                </div>
            </div>
            <div className="detail_cont">
                <div className="course__numericsI">
                    <div className="top">
                        <h3 className="icon_et_info">
                            <span>
                                <img src={Students} alt="icon" />
                            </span>
                            <span>1,356</span>
                        </h3>
                        <p>Students enrolled</p>
                    </div>
                    <div className="bottom">
                        <h3 className="icon_et_info">
                            <span>
                                <img src={Star} alt="icon" />
                            </span>
                            <span>4.3</span>
                        </h3>
                        <p>Star rating</p>
                    </div>
                </div>
                <div className="course__numericsII">
                    <div className="top">
                        <h3 className="icon_et_info">
                            <span>
                                <img src={Euro} alt="icon" />
                            </span>
                            <span>&euro;254</span>
                        </h3>
                        <p>Earnings this month</p>
                    </div>
                    <div className="bottom">
                        <h3 className="icon_et_info">
                            <span>
                                <img src={Euro} alt="icon" />
                            </span>
                            <span>&euro;1305</span>
                        </h3>
                        <p>Total earnings</p>
                    </div>
                </div>
            </div>
            <div className="course__status">
                {/* <Link to="/tutor"> */}
                <CTA
                    type="primary"
                    style={{
                        backgroundColor: '#C1CDF8',
                        color: '#3A86FF',
                        borderRadius: '6px',
                        border: 'none',
                        cursor: 'not-allowed'
                    }}
                > Update
                </CTA>
                {/* </Link> */}
                <CTA
                    type="primary"
                    danger
                    style={{
                        borderRadius: '6px',
                        cursor: 'not-allowed'
                    }}
                    // onClick={() => setOpenModal(true)}
                > Delete
                </CTA>
            </div>
            <Modal
                centered
                keyboard="true"
                open={openModal}
                footer={null}
                onOk={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
                style={{
                    textAlign: 'center',
                }}
                // width="0"
            >
                <div
                    style={{
                        marginBlock: '2em',
                    }}
                >
                    <h4
                        style={{
                            color: '#3a86ff'
                        }}
                    >Are you sure you want to delete AI technology masterclass
                    </h4>
                    <p>If you decide to delete AI Technology masterclass, you will never get a chance to bring it back</p>
                    <div
                        style={{
                            marginBlock: '1em 0.5em',
                            display: 'flex',
                            height: '80px',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            size="middle"
                            type="primary"
                            style={{
                                paddingInline: '2.3em',
                                borderRadius: '10px',
                            }}
                        >Cancel
                        </Button>
                        <Button
                            size="middle"
                            type="primary"
                            style={{
                                borderRadius: '10px',
                                paddingInline: '2em'
                            }}
                        >Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
