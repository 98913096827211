import React, { useEffect, useState } from 'react'
import Carousel from '../Courses/HeroSection/Carousel'
import SearchBar from '../premiumCourses/SearchBar/SearchBar'
import CourseCard from '../premiumCourses/CourseCard/CourseCard'
import {
    // findAllCourse,
    getCourseByTypeId,
} from '../../../services'
import { RequestLoader } from '../../../components/Loading'
import './style.scss'

export const InstitutionalCourse = () => {
    const [courses, setCourses] = useState([])
    const [isLoading, setIsLoading] = useState('')

    useEffect(() => {
        const getInstitutionalCourses = async () => {
            setIsLoading(true)
            try {
                // const res = await findAllCourse(20)
                const res = await getCourseByTypeId(1, 20, 3)
                setCourses(
                    // res?.data.filter((course) => course.courseType === 3)
                    res?.data
                )
                setIsLoading(false)
            } catch (err) {
                console.error(err)
                setIsLoading(false)
            }
        }
        getInstitutionalCourses()

        return () => {
            setCourses([])
        }
    }, [])

    return (
        <div id="institutional-course-page">
            <div>
                <Carousel />
                <SearchBar />
                <h4 style={{ color: '#3A86FF' }}>Single Course Subscription</h4>
            </div>
            {isLoading && (
                <div className="loader_cont">
                    <RequestLoader />
                </div>
            )}
            {!isLoading && (
                <div className="institutional-courses-container">
                    {courses &&
                        courses
                            .filter((course) => course.courseApproval === 2)
                            .map((course, i) => (
                                <CourseCard key={i} course={course} />
                            ))}
                </div>
            )}
        </div>
    )
}