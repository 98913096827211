import React, { useState, useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { PaystackButton } from 'react-paystack'
import { FiChevronLeft } from 'react-icons/fi'
import { useAuth } from '../../../hooks'
import { Input } from '../../Input/Input'
import { CartContext } from '../../../context/Cart/cartContext'
import './styles.scss'
import ImageCourse from '../assets/thumbnail.png'
import CheckoutForm from '../CheckoutStripe/CheckoutForm'
import { createPremiumPayment, applyPromoCode } from '../../../services'
import { openNotification, useGeoLocation } from '../../../utils'
import '../CheckoutStripe/checkoutStripe.scss'

const stripePromise = loadStripe(
    // process.env.REACT_APP_STRIPE_PUBLIC_KEY
    'pk_test_51JaTU7Hi1Iw0XNAQ4gghRCbsaxAFO280HeIEFq8wOGWL5wRUkWjM3DHejEwvc5Q5aCzhmSGxvItWff6bRA7fve1H00gtGuXAdg'
) // FOR STRIPE
const paystack_publicKey = 'pk_test_5c1375018d92a63e0328366a82e7f55f09d464ed' // FOR PAYSTACK
// const paystack_publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY // FOR PAYSTACK

export const CartItems = () => {
    const {
        state: { data: userData },
    } = useAuth()
    const { cart, removeItem, cartItems, clearCart } = useContext(CartContext)
    const [clientSecret, setClientSecret] = useState('')
    const [promoCode, setPromoCode] = useState('')
    const [promoCodeError, setPromoCodeError] = useState(null)
    const [amount, setAmount] = useState('')
    const [paystackAmount, setPaystackAmount] = useState('')
    const [discountPercent, setDiscountPercent] = useState('0')
    const [discountedAmount, setDiscountedAmount] = useState('')
    const [subtractedAmount, setSubtractedAmount] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [symbol, setSymbol] = useState('')
    // const [isProcessing, setIsProcessing] = useState(false)
    const { country } = useGeoLocation()
    const history = useHistory()

    // useEffect(() => {
    //     const symbol = () => {
    //         if (country === 'NG') {
    //             setSymbol('₦')
    //         } else if (country === 'GB') {
    //             setSymbol('£')
    //         }
    //     }
    //     symbol()
    // }, [country])

    // handles dynamic currency for user country location
    useEffect(() => {
        const symbol = () => {
            if (country !== 'NG' && country !== 'ZA' && country !== 'GH' && country !== 'KE') {
                setSymbol('£')
            } else {
                setSymbol('₦')
            }
            console.log(country)
        }
        symbol()

    }, [country])

    const navigateSuccessPage = () => {
        history.push('/me/premium/success')
    }

    const handlePromoCodeSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const promoData = {
            price: cart.total,
            code: promoCode,
        }
        try {
            const response = await applyPromoCode(promoData)
            const { discountedAmount, originalAmount } = response.data
            setAmount(discountedAmount)
            setDiscountedAmount(discountedAmount)
            // calculate discount amount percentage
            const discountPercentage = ((originalAmount - discountedAmount) / originalAmount) * 100
            setDiscountPercent(discountPercentage)
            const subtractAmount = originalAmount - discountedAmount
            setSubtractedAmount(subtractAmount)
        } catch (error) {
            setPromoCodeError(error.message)
        }
        setIsLoading(false)
    }

    // stores the ids of all cart items in an array
    const premiumCourseIds = cartItems.map((item) => item.id)
    // returns "ng" if country is nigeria to action paystack else returns "gb" for stripe
    const cuRR = () => {
        if (['NG', 'ZA', 'GH', 'KE'].includes(country)) {
            return 'ng'
        } else {
            return 'gb'
        }
    }

    const checkoutData = {
        premiumCourseId: premiumCourseIds,
        promoCode: promoCode,
        currency: cuRR(),
        email: '',
        amount: '',
        cardNumber: '',
        cardCvv: '',
        cardExpiryMonth: '',
        cardExpiryYear: '',
        pin: '',
    }

    const handleCheckout = async () => {
        // setIsProcessing(true)
        setIsLoading(true)
        try {
            const response = await createPremiumPayment(checkoutData)
            // Checks currency if "gb" it runs for stripe else if "ng" it runs for paystack
            if (checkoutData.currency === 'gb') {
                setClientSecret(response?.data?.clientSecret)
            } else {
                setPaystackAmount(
                    response?.data?.discountedAmount
                        ? response.data.discountedAmount
                        : response.data.amount
                )
                // setAmount(
                //     response?.data?.discountedAmount
                //         ? response.data.discountedAmount
                //         : response.data.amount
                // )
            }
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Checkout',
                message: error.message,
            })
            // setIsProcessing(false)
            setIsLoading(false)
        }
    }

    // FOR STRIPE
    const appearance = {
        theme: 'stripe',
    }
    const options = {
        clientSecret: clientSecret,
        appearance: appearance,
    }

    // FOR PAYSTACK
    const componentProps = {
        email: userData.email,
        amount: paystackAmount * 100,
        metadata: {
            name: userData.firstName + ' ' + userData.lastName,
            // phone: userData.phone,
        },
        publicKey: paystack_publicKey,
        text: 'Pay Now',
        onSuccess: () => {
            // setTimeout(() => {
            //     clearCart()
            // }, [3000])
            clearCart();
            navigateSuccessPage();
        },
        onClose: () => {
            setIsLoading(false)
            setPaystackAmount(false)
            // setIsProcessing(false)
        },
    }

    return (
        <div className="cart-page">
            <h4 className="title">Shopping Cart</h4>
            <div className="content-group">
                <div className="left-content">
                    <div className="left-heading">
                        <h4 className="course-detail-head">Course Details</h4>
                        <h4 className="price-head">Price</h4>
                    </div>
                    <div className="left-details">
                        <div>
                            {cartItems.length === 0 ? (
                                <div>Cart is Empty.</div>
                            ) : (
                                cartItems.map((item) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className="info-container"
                                        >
                                            <div className="section-one">
                                                <img
                                                    src={ImageCourse}
                                                    className="img-course"
                                                    alt=""
                                                />
                                                <div className="course-desc">
                                                    <p>Learn {item.name}</p>
                                                    <p>
                                                        By {item.instructorName}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="section-two">
                                                <h6 className="price">
                                                    {symbol}
                                                    {item.amount}
                                                </h6>
                                                <button
                                                    className="remove-btn"
                                                    onClick={() => removeItem(item.id)}
                                                >
                                                    Remove
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            )}
                        </div>
                    </div>
                </div>

                <div className="right-content">
                    <div className="summary">
                        <h4>Summary</h4>
                    </div>
                    <div className="items-count">
                        <p>No. of Items {cartItems.length}</p>
                        <p>
                            {symbol}
                            {cart.total}
                        </p>
                    </div>
                    <form onSubmit={handlePromoCodeSubmit}>
                        {/* * <p>Promo code</p> * */}
                        <Input
                            className="promo-code"
                            placeholder="Enter Promo Code"
                            name="promo-code"
                            id="promo-code"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            // disabled={isLoading}
                        />
                        {promoCodeError ?
                            <p className="promo-error">
                                {promoCodeError}
                            </p>
                            : null}
                        <button className="apply-btn" type="submit">
                            Apply
                        </button>
                    </form>
                    <div className="subtotal">
                        <p>Subtotal</p>
                        <p
                            style={{
                                textDecoration: discountedAmount
                                    ? 'line-through'
                                    : 'none',
                            }}
                        >
                            {symbol}
                            {cart.total}
                        </p>
                    </div>
                    <div className="discount">
                        <p>Discount ({discountPercent}%)</p>
                        <p>
                            {discountedAmount
                                ? `${symbol + subtractedAmount}`
                                : null}
                        </p>
                    </div>
                    <div className="pay-item">
                        <p>Pay</p>
                        {paystackAmount || amount ? (
                            <p>
                                {symbol} {paystackAmount || amount}
                            </p>
                        ) : (
                            symbol + cart.total
                        )}
                    </div>
                    {/* CHECKOUT BUTTON */}
                    <div>
                        <button
                            // style={{ display: isLoading ? 'none' : 'block' }}
                            style={{ background: isLoading ? '#99999d' : '#3A86FF' }}
                            className="checkout"
                            type="submit"
                            onClick={handleCheckout}
                            disabled={isLoading}
                        >
                            Checkout
                        </button>
                        {/* Triggers paystack if amount has been passed to props on clicking checkout btn */}
                        {paystackAmount && (
                            <PaystackButton
                                className="paystack-btn"
                                {...componentProps}
                            />
                        )}
                        {/* <button onClick={() => clearCart()}>Clearrr</button> */}
                    </div>

                    {/* Triggers stripe checkout form */}
                    {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm clientSecret={clientSecret} />
                        </Elements>
                    )}
                    {/* <button className="checkout">Checkout</button> */}
                </div>
            </div>
            <div>
                <Link className="link" to="/me/premium">
                    <FiChevronLeft />
                    Continue Shopping
                </Link>
            </div>
        </div>
    )
}