import React, { useState } from 'react'
import { Form, Modal } from 'antd'
import { Input, Button } from '../../../../components'
import { useAuth } from '../../../../hooks'
import { openNotification } from '../../../../utils'
import './CloseAccount.scss'

export const CloseAccount = () => {
    const {
        state: { data },
        deleteAccount,
    } = useAuth()
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState(null)
    const [showModal, setShowModal] = useState(false)

    const [form] = Form.useForm()
    const [validationForm] = Form.useForm()

    const handleDeleteAccount = async () => {
        setLoading(true)
        try {
            if (email.toLowerCase() !== data?.email?.toLowerCase()) {
                throw new Error(
                    'The entered email address does not belong to you 😉'
                )
            }
            await deleteAccount(email)

            setShowModal(false)
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Delete Account',
                message: error.message,
            })
        }
        setLoading(false)
    }

    return (
        <div id="close-account">
            <div className="contents">
                <h4>Close Account</h4>
                <p>
                    Warning this will permanently delete your account and you
                    will permanently loose access to your courses and will lose
                    all personal infomation. This action is irreversible. to
                    prevent accidental closing of the account a verification
                    code will be sent to you to complete the closing of the
                    account
                </p>
                <Form
                    name="form"
                    form={form}
                    // onFinish={handleDeleteAccount}
                    onFinish={(values) => {
                        setShowModal(true)
                        setEmail(values.email)
                    }}
                    autoComplete="off"
                >
                    <Input
                        name="email"
                        validationRules={[
                            {
                                required: true,
                                message: 'Please enter your email',
                            },
                            {
                                type: 'email',
                                message: 'This email is not valid',
                            },
                        ]}
                        placeholder="Enter your Email address"
                        disabled={loading}
                    />
                    <Button
                        className="btn"
                        // loading={loading}
                        type="submit"
                        disabled={loading}
                    >
                        Close Account
                    </Button>
                </Form>
                <Modal
                    title={null}
                    // visible={showModal}
                    open={showModal}
                    // onOk={handleOk}
                    closeIcon={null}
                    footer={null}
                    destroyOnClose
                    closable={false}
                    // onCancel={handleCancel}
                >
                    <p className="text-center text-secondary">
                        Enter <em className="text-dark font-italic">delete</em>{' '}
                        to proceed
                    </p>
                    <Form
                        className="mt-4"
                        form={validationForm}
                        autoComplete="off"
                        onFinish={handleDeleteAccount}
                    >
                        <Input
                            validationRules={[
                                () => ({
                                    validator(_, value) {
                                        if (value === 'delete') {
                                            return Promise.resolve()
                                        }
                                        return Promise.reject(new Error(' '))
                                    },
                                }),
                            ]}
                            name="confirmText"
                            disabled={loading}
                            className="py-2 border-none"
                        />
                        <Form.Item shouldUpdate className="submit">
                            {() => (
                                <>
                                    <Button
                                        type="submit"
                                        loading={loading}
                                        disabled={
                                            !validationForm.isFieldsTouched(
                                                true
                                            ) ||
                                            validationForm
                                                .getFieldsError()
                                                .filter(
                                                    ({ errors }) =>
                                                        errors.length
                                                ).length > 0 ||
                                            loading
                                        }
                                    >
                                        Proceed to close account
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={() => setShowModal(false)}
                                        loading={loading}
                                        type="button"
                                        disabled={loading}
                                    >
                                        Cancel
                                    </Button>
                                </>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    )
}
