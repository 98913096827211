// import images
import teachImg from '../assets/what_it_takes.png';
import ellipseSolid from '../assets/Ellipse_1.png';
import ellipseDashed from '../assets/Ellipse_2.png';
// import styles
import './what.scss';

const What = () => {
    return (
        <section id="what">
            <div className="container">
                <h2>What it takes</h2>
                <div className="content">
                    <div className="images">
                        <div className="img_cont main-img">
                            <img
                                src={teachImg}
                                alt="women writing notes and pointing to a drawing board"
                            />
                        </div>
                        <div className="img_cont elipse-graphic e1">
                            <img
                                src={ellipseSolid}
                                alt="elipse graphic"
                            />
                        </div>
                        <div className="img_cont elipse-graphic e2">
                            <img
                                src={ellipseDashed}
                                alt="elipse graphic"
                            />
                        </div>
                    </div>
                    <div className="text-content">
                        <div className="item">
                            <h3>Industry Leader</h3>
                            <ul>
                                <li>
                                    We want to work with instructors who have an understanding
                                    of what it takes to succeed in their industry and how best
                                    to transfer the skills and knowledge needed to do so to our
                                    students.
                                </li>
                            </ul>
                        </div>
                        <div className="item">
                            <h3>Commited Educators</h3>
                            <ul>
                                <li>
                                    We want to work with instructors who are willing to provide the best value for our students.
                                </li>
                                <li>
                                    We want instructors who are passionate about
                                    the learning process and how best to deliver
                                    the learning contents and materials.
                                </li>
                            </ul>
                        </div>
                        <div className="item">
                            <h3>Impact Driven Learners</h3>
                            <ul>
                                <li>
                                    We want to work with instructors who are continually learning
                                    and improving their delivery to provide the best possible value
                                    to our students.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default What;
