import { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { DashboardLayout } from '../../components'
import { instructorDashboardRoutes } from '../../constants'
import { NotFound } from './NotFound'
import { InstructorDB } from './Home'
import { Settings } from './Settings'
import { InstructorCourses } from './ManageCourses'
import { DeliveryComp } from './CreateEditCourses'
import { setTasks } from './SetTasks'
import { EditLesson } from './CreateEditCourses/EditLesson/index.jsx'
import { EditModule } from './CreateEditCourses/EditModule'
import { ReviewCourse } from './CreateEditCourses/ReviewCourse'
// import { EditCourse } from './CreateEditCourses/EditCourse'
import { Successpage } from './CreateEditCourses/SuccessMessage/index.jsx'
import { QuizCreationPage } from './Quiz/QuizCreationPage'
// import { QuizPreviewPage } from './Quiz/QuizPreviewPage'
import { CourseDetails } from '../userDashboard/CourseDetails'
import PaymentDashboard from './PaymentDashboard/PaymentDashboard'
import { useInstructorData, useAuth } from '../../hooks'

const routes = [
    {
        path: '/tutor',
        name: 'Dashboard',
        exact: true,
        component: InstructorDB,
    },
    {
        path: '/tutor/success',
        name: 'Success Message',
        exact: true,
        component: Successpage,
    },
    {
        path: '/tutor/create-course',
        name: 'Create Course',
        exact: true,
        component: DeliveryComp,
    },

    {
        path: '/tutor/edit-course',
        name: 'Edit Course',
        exact: true,
        component: DeliveryComp,
    },

    {
        path: '/tutor/settings',
        name: 'Settings',
        // exact: true,
        component: Settings,
    },
    {
        path: '/tutor/courses',
        name: 'Courses',
        exact: true,
        component: InstructorCourses,
    },
    {
        path: '/tutor/tasks',
        name: 'Manage Tasks',
        component: setTasks,
    },
    {
        path: '/tutor/revenue',
        name: 'Revenue/Payment',
        component: PaymentDashboard,
    },
    {
        path: '/tutor/create-lesson',
        name: 'Create Lesson',
        component: DeliveryComp,
    },

    {
        path: '/tutor/edit-lesson',
        name: 'Edit Lesson',
        component: EditLesson,
    },
    {
        path: '/tutor/review-course',
        name: 'Review course',
        component: ReviewCourse,
    },
    {
        path: '/tutor/Preview-course',
        name: 'Preview course',
        component: DeliveryComp,
    },
    // Add new pages aboved this or they will not work ↓↓
    {
        path: '/tutor/create-module',
        name: 'Create Module',
        exact: true,
        component: DeliveryComp,
    },
    {
        path: '/tutor/edit-module',
        name: 'Edit Module',
        component: EditModule,
    },
    {
        path: '/tutor/apageCreation',
        name: 'Create Complete Course',
        component: DeliveryComp,
    },
    {
        path: '/tutor/stats',
        name: 'test',
        exact: true,
        // component: ModuleCard,
    },
    {
        path: '/tutor/create-quiz',
        name: 'Create quiz',
        exact: true,
        component: QuizCreationPage
    },
    {
        path: '/tutor/preview-quiz/:id',
        name: 'Preview quiz',
        exact: true,
        component: CourseDetails
    },
    {
        path: '',
        name: 'Not Found',
        exact: true,
        component: NotFound,
    },
]

export const InstructorDashboard = () => {
    const {
        state: { data }
    } = useAuth()

    const {
        getInstructorID
    } = useInstructorData()

    useEffect(() => {
        const isInstructor = () => {
            try {
                getInstructorID(data?.id)
            } catch (error) {
                console.error(error.message)
            }
        }
        isInstructor()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.id])
    return (
        <DashboardLayout routeList={instructorDashboardRoutes}>
            <Switch>
                {routes.map((route) => {
                    return (
                        <Route
                            key={route.path}
                            // path={`/tutor${route.path}`}
                            {...route}
                        />
                    )
                })}
            </Switch>
        </DashboardLayout>
    )
}