import { useState, useEffect } from 'react'
// import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Virtual, Navigation, Pagination } from 'swiper'
import './style.scss'
import 'swiper/swiper.scss'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
// import { CartContext } from '../../../../context/Cart/cartContext'
// import { HiChevronLeft, HiChevronRight } from 'react-icons/hi'
import {
    findAllCourse,
    // getPremiumCourses,
} from '../../../../services'
import CourseCard from '../CourseCard/CourseCard'
// import { Detail } from '../../../../components/PremiumCourse/Detail'
// install Virtual module
SwiperCore.use([Virtual, Navigation, Pagination])

const CoursesContent = () => {
    const [courses, setCourses] = useState([])
    // const courseIdAddress = window.location.pathname
    // const courseId = courseIdAddress.split('/').pop()

    useEffect(() => {
        const premiumCourses = async () => {
            try {
                const res = await findAllCourse(34)
                // console.log(res)
                // const res = await getPremiumCourses()
                const premCourse = res?.data?.filter(
                    (res) => res.courseType === 2
                )
                // console.log(premCourse)
                setCourses(premCourse)
            } catch (err) {
                console.error(err)
            }
        }
        premiumCourses()
    }, [])

    return (
        <div className="cards-section top-card">
            <div style={{ paddingTop: '2em' }} className="top-picks-container">
                {courses
                    .filter((course) => course.courseApproval === 2)
                    .map((course) => (
                        <CourseCard key={course.id} course={course} />
                    ))}
            </div>
        </div>
    )
}

export default CoursesContent

// .filter((course) => course.courseApproval === 2)