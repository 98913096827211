import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal } from 'antd'
// Style sheet is in parent folder ../courseCard.scss
// import { findLessonByModuleId } from '../../../../services'

export function QuizCard({ type, thumbnail, CTA }) {
    const [btnTxt, setBtntxt] = useState('')
    const [status, setStatus] = useState('')
    const [editBtn, setEditbtn] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    // const [lessonsNum, setLessonsNum] = useState(0)
    useEffect(() => {
        switch (type) {
            case 'active':
                setEditbtn(true)
                setStatus('Active Module')
                setBtntxt('Delete')
                break;
            case 'review':
                setStatus('In-review')
                setBtntxt('Locked')
                break;
            case 'retired':
                setStatus('Retired')
                setBtntxt('Closed')
                break;
            case 'SIC':
                setEditbtn(true)
                setStatus('Still-in-creation')
                setBtntxt('Delete')
                break;
            default:
                setBtntxt('No action')
                setStatus('No status')
                break;
        }
    }, [type])
    return (
        <div className="course__card">
            <div className="course__thumbnail">
                <img src={thumbnail} alt="thumbnail" />
            </div>
            <div className="course__details">
                <h3 title={type.title}>{type.title}</h3>
                <div
                    style={{
                        width: '100%',
                        margin: '0 auto'
                    }}
                >
                    <p>Published 01/02/23</p>
                    <button>{status}</button>
                </div>
            </div>
            <div className="module detail_cont">
                <div
                    // style={{
                    //     width: '35%'
                    // }}
                    className="course__numericsI"
                >
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        className="top"
                    >
                        <p>Questions</p>
                        <h3>{type.totalQuestions}</h3>
                    </div>
                </div>
            </div>
            <div className="module course__status">
                {editBtn &&
                <Link to="/">
                    <CTA
                        type="primary"
                        style={{
                            backgroundColor: '#C1CDF8',
                            color: '#3A86FF',
                            borderRadius: '6px',
                            border: 'none',
                            cursor: 'not-allowed'
                        }}
                    > {type === 'active' ? 'Update' : 'Edit'}
                    </CTA>
                </Link>}
                <CTA
                    type="primary"
                    danger
                    style={{
                        borderRadius: '6px',
                        cursor: 'not-allowed'
                    }}
                    onClick={() => {
                        type === 'active' || type === 'SIC' ? setOpenModal(true) : setOpenModal(false)
                    }}
                >{btnTxt}
                </CTA>
            </div>
            <Modal
                centered
                keyboard="true"
                open={openModal}
                footer={null}
                onOk={() => setOpenModal(false)}
                onCancel={() => setOpenModal(false)}
                style={{
                    textAlign: 'center',
                }}
            >
                <div
                    style={{
                        marginBlock: '2em',
                    }}
                >
                    <h4
                        style={{
                            color: '#3a86ff'
                        }}
                    >Are you sure you want to delete AI technology masterclass
                    </h4>
                    <p>If you decide to delete AI Technology masterclass, you will never get a chance to bring it back</p>
                    <div
                        style={{
                            marginBlock: '1em 0.5em',
                            display: 'flex',
                            height: '80px',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            size="middle"
                            type="primary"
                            style={{
                                paddingInline: '2.3em',
                                borderRadius: '10px',
                            }}
                        >Cancle
                        </Button>
                        <Button
                            size="middle"
                            type="primary"
                            style={{
                                borderRadius: '10px',
                                paddingInline: '2em'
                            }}
                        >Confirm
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}