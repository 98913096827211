import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ReactPlayer from 'react-player'
import axios from 'axios'
import { Button, Tabs, Modal, Collapse } from 'antd'
import { useInstructorData } from '../../../../../hooks'
import '../../../../userDashboard/CourseDetails/courseOverview.scss'
import Overview from './tabs/overview'
import Module, { ModuleHeader } from './modules'
import { openNotification } from '../../../../../utils/helpers'
import {
    createCourse,
    addModuleToCourse,
    findModuleById,
    findLessonByModuleId,
    FindLessonsById
} from '../../../../../services'
import DoneLoading from '../../../../../assets/icons/Done_loading.svg'
import { RequestLoader } from '../../../../../components/Loading';

export const PreviewCourse = () => {
    const {
        state: { instructorID }
    } = useInstructorData()
    // Route was prepare to accept a parameter, destructured the parameter with the hook
    const history = useHistory()
    const [total, setTotal] = useState(0)
    const [courseInfo, setCourseInfo] = useState({})
    const [openModal, setOpenModal] = useState(false)
    const [url, setUrl] = useState('https://www.youtube.com/watch?v=NGuIezLFidY')
    const [modules, setModules] = useState({})
    const [relatedModules, setRelatedModule] = useState({})
    const [courseLesson, setCourseLesson] = useState({})
    const store = JSON.parse(localStorage.getItem('Preview'))
    const attachedModules = JSON.parse(localStorage.getItem('Course_Related_data'))
    const [isLoading, setIsloading] = useState(false)
    const [ready, setReady] = useState(false)
    const video = document.createElement('video')
    const [duration, setDuration] = useState('0')
    const [playerCoverImg, setPlayerCoverImage] = useState('https://courseresource.s3.us-east-2.amazonaws.com/assets/b4428482-6aa5-4e95-bff7-57aaf714166f/samuel-regan-asante-Rk8fHGGeyr8-unsplash.jpg')

    const { Panel } = Collapse;

    // Get all about the coure from Localstorage
    useEffect(() => {
        const getCourse = () => {
            if (Object.keys(store).length > 1) {
                setCourseInfo(store)
                setPlayerCoverImage(store.coursePicture)
                const introObj = {}
                store.introLessons.sort((a, b) => a.key - b.key)
                store.introLessons.forEach(async (intro) => {
                    const res = await FindLessonsById(intro.id)
                    introObj[intro.index] = res.data
                })
                // setUrl(store?.thumbnailURL?.info?.fileNameUrl)
                // Need to extract the object of modules related to the course
                setRelatedModule(attachedModules.module)
                setCourseLesson(introObj)
                setUrl(introObj[1]?.content)
            } else {
                openNotification({
                    title: 'Data not detailed Enough',
                    type: 'Error',
                    message: 'Kindly Fill Up All Field In The Form'
                })
                history.push('/tutor/create-course')
            }
        }
        getCourse()
        return () => {
            setCourseInfo({})
            setRelatedModule({})
            setCourseLesson({})
        }
        // eslint-disable-next-line
    }, [])
    // Get all info related to a specific module (main reason is to get their lessons)
    useEffect(() => {
        const source = axios.CancelToken.source()
        const getModule = async () => {
            try {
                const tempObj = {}
                if (Object.keys(relatedModules).length !== 0) {
                    Object.keys(relatedModules).forEach(async (key) => {
                        // fetching info bout module using the module id
                        const res = await findModuleById(relatedModules[key].key)
                        tempObj[key] = res.data
                    })
                    // const firstUrl = getFirstLesson(tempObj)
                    // console.log(firstUrl)
                    // setUrl(firstUrl)
                    setModules(tempObj)
                    // state in which lessons of specific module depends on
                    setReady(true)
                } else {
                    throw new Error('ModuleStore is empty')
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.error('Error coming from cleanUp function')
                } else {
                    console.error(error.message)
                }
            }
        }

        getModule()
        return () => {
            setModules({})
            setReady(false)
            source.cancel('fetching of module data got cancelled')
        }
        // eslint-disable-next-line
    }, [relatedModules])

    // Get all Lessons in the course
    // Function won't be executed not until "ready" state changes
    useEffect(() => {
        const source = axios.CancelToken.source()
        const currentModuleData = modules
        const getLesson = async () => {
            try {
                if (Object.keys(relatedModules).length !== 0) {
                    Object.keys(relatedModules).forEach(async (id) => {
                        const res = await findLessonByModuleId(relatedModules[id].key)
                        setTotal(prev =>  prev + res.data.length)
                        const lessonList = {}
                        for (let i = 0; i < res.data.length; i++) {
                            lessonList[res.data[i].index] = res.data[i]
                        }
                        if (currentModuleData?.[id]) {
                            currentModuleData[id] = { ...currentModuleData[id], 'Lessons': lessonList }
                        }
                        setModules(currentModuleData)
                    })
                } else {
                    throw new Error('Module is empty')
                }
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.error('Error occured when aborting get request')
                } else {
                    console.error(error.message)
                }
            }
        }
        getLesson()
        return () => {
            source.cancel('fetching lessons request cancelled')
            setTotal(0)
            setModules(currentModuleData)
        }
        // eslint-disable-next-line
    }, [ready])

    // const getFirstLesson = (obj) => {
    //     let url;
    //     Object.values(obj).forEach((module) => {
    //         if (module.moduleName === 'Introduction') {
    //             for (let i = 0; i < module.lessons.length; i++) {
    //                 if (module.lessons[i].index === 1) {
    //                     url = module.lessons[i].content
    //                 }
    //             }
    //         }
    //     })
    //     return url
    // }

    useEffect(() => {
        function handleLoad() {
            window.scrollTo(0, 0);
        }
        window.addEventListener('load', handleLoad);
        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    const tabs = [
        {
            key: '1',
            label: 'Overview',
            children: <Overview instructorID={instructorID} course={courseInfo} total={total} />,
        },
        {
            key: '2',
            label: 'Tasks',
            children: 'Task tab',
        },
        {
            key: '3',
            label: 'Q&A',
            children: 'Q&A tab',
        },
        {
            key: '4',
            label: 'Review',
            children: 'Review tab',
        },
        {
            key: '5',
            label: 'Annoucement',
            children: 'Annoucement tab',
        },
        {
            key: '6',
            label: 'Transcript',
            children: 'Transcript tab',
        }
    ]

    const handleSubmit = async () =>  {
        setIsloading(true)
        const response = await createCourse(store)
        if (response.statusCode !== 400) {
            const courseId = response?.data?.id
            const attachResource = (index, moduleId) => {
                const data = {
                    'moduleId': moduleId,
                    'courseId': courseId,
                    'index': index
                }
                return data
            }
            const promisArry = []
            for (let i = 1; i <= Object.keys(relatedModules).length; i++) {
                try {
                    const prom1 = addModuleToCourse(attachResource(i, relatedModules[i].key))
                    promisArry.push(prom1)
                } catch (error) {
                    console.error(error)
                }
            }
            Promise.all(promisArry)
                .then((result) => {
                    openNotification({
                        title: 'Course creation',
                        type: 'success',
                        message: result[0].message,
                    })
                    setIsloading(false)
                    const intervalId = setInterval(() => {
                        setOpenModal(false)
                        // Clear Local storage here
                        history.push('/tutor/success')
                    }, 5000)
                    setTimeout(() => {
                        clearInterval(intervalId)
                    }, 7000)
                })
                .catch(error => {
                    openNotification({
                        title: 'Course creation',
                        type: 'error',
                        message: error.message
                    })
                    setIsloading(false)
                    setOpenModal(false)
                })
        } else {
            openNotification({
                title: 'Course creation',
                type: 'error',
                message: response.message
            })
            setOpenModal(false)
            history.push('/tutor')
            localStorage.removeItem('Preview')
            localStorage.removeItem('Course Modules')
        }
    }

    return (
        <main className="course_overview">
            <div className="heading_cont">
                <h2>{courseInfo?.name}</h2>
                <div
                    className="control_btn"
                >
                    <Button
                        className="edit_btn"
                        type="primary"
                        onClick={() => {
                            // also on the preview page we've got edit button, (leads to a form, getting data as prop)
                            history.push('/tutor/edit-course')
                        }}
                    >Edit
                    </Button>
                    <Button
                        className="submit_btn"
                        onClick={() => {
                            // on the preview page we submit (function, modal, and redirect)
                            setOpenModal(true)
                            handleSubmit()
                        }}
                        type="primary"
                    >Submit
                    </Button>
                </div>
            </div>
            <section className="course_content">
                <div className="content">
                    <div className="video_cont">
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            playing={true}
                            // You can pass the thumbnail to the light attribute, this will be the coursePicture
                            light={<img src={playerCoverImg} alt="Thumbnail" />}
                            url={url}
                            controls={true}
                            config={{
                                file: {
                                    attributes: {
                                        disablepictureinpicture: 'true',
                                        controlsList:
                                            'nodownload noplaybackrate nofullscreen',
                                    },
                                },
                            }}
                        />
                    </div>
                    <div className="tab_cont">
                        <Tabs
                            defaultActiveKey="1"
                            items={tabs}
                            centered
                            size="large"
                            tabBarGutter="5em"
                        />
                    </div>
                </div>
                <aside>
                    <h2 className="heading">Content</h2>
                    <div className="introLessons_cont">
                        <Collapse
                            defaultActiveKey={['100']}
                            expandIconPosition="end"
                            bordered="false"
                        >
                            <Panel header={<ModuleHeader itm={{ name: 'Introductory Lessons', Lessons: courseLesson }} />} key="100" showArrow="false">
                                {courseLesson && Object.entries(courseLesson).map(([pos, itm]) => {
                                    video.src = itm?.content
                                    video.addEventListener('loadedmetadata', () => {
                                        const num = (video.duration / 60) < 10 ? `0${(video.duration / 60).toFixed()}` : `${(video.duration / 60).toFixed()}`
                                        const num2 = (video.duration % 60) < 10 ? `0${(video.duration % 60).toFixed()}` : `${(video.duration % 60).toFixed()}`
                                        console.log(num, num2)
                                        setDuration(`${num}:${num2}`)
                                    })

                                    return (
                                        <div
                                            className="lesson_item"
                                            key={pos}
                                            onClick={() => {
                                                setUrl(itm?.content)
                                            }}
                                        >
                                            <p title={itm.name}>{itm.name}</p>
                                            <div>
                                                <p>{duration}</p>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Panel>
                        </Collapse>
                    </div>
                    <div className="module_cont">
                        {modules && <Module mod={modules} updateUrl={setUrl} />}
                    </div>
                </aside>
            </section>
            {/* Modal going to the preview page, should be visibile when the submit button in the preview page is clicked */}
            <Modal
                centered
                footer={null}
                open={openModal}
                style={{
                    textAlign: 'center',
                }}
            >
                <div>
                    <h4 style={{ color: '#3a86ff' }}>{isLoading ? 'Creating Course' : 'Course Created Successfully'}</h4>
                    <div style={{ marginBlock: '2em' }}>
                        {isLoading && <RequestLoader />}
                        {!isLoading &&
                            <>
                                <img src={DoneLoading} alt="done_loading" />
                                <div>
                                    <p style={{ marginBlock: '1em' }}>
                                        <span style={{ color: '#3a86ff' }}>NB:</span> Our team will review your course before acceptance and you will not have access to make any changes to your course until we are done with the review.
                                    </p>
                                </div>
                            </>}
                    </div>
                </div>
            </Modal>
        </main>
    )
}
