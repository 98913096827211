import React, { useState, useEffect } from 'react';
import { Menu, Checkbox, Dropdown, Input, Button } from 'antd';
import { Link } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons';
import { Lessoncard } from '../../MyCourses/courseCard/index'
import SearchIcon from '../../../../assets/icons/search.svg'
import { useInstructorData } from '../../../../hooks';
import { RequestLoader } from '../../../../components/Loading'
// import { FindLessonsByInstructorId } from '../../../../services'
// import axios from 'axios';

export function LessonSecCont() {
    const {
        state: { isLoading, lessons, instructorID }
    } = useInstructorData()

    const [list, setList] = useState([])
    const [inputValue, setInputvalue] = useState('')
    const onChange = (e) => {
        const val = e.target.checked
        // item has to be checked before been added to list
        if (e.target.checked) {
            // check of value exist in list
            list.includes(val) ? console.log('value exist already') : setList([...list, val])
        } else {
            // remove from the list, utilizing the return array of filter to update prevArry
            const newList = list.filter(lst => lst !== val)
            setList(newList)
        }
    };

    const handleSearch = () => {
        console.log(`Search icon Clicked, content of input field is '${inputValue}'`)
    }

    useEffect(() => {
        // const getAllLesson = async () => {
        //     try {
        //         const response = await FindLessonsByInstructorId(instructorID)
        //         setList(response.data)
        //     } catch (error) {
        //         console.log(error)
        //     }
        // }
        // getAllLesson()
        if (instructorID) {
            setList(lessons)
        }
        return () => {
            setList([])
        }
    }, [instructorID, lessons])

    const menu =
        <Menu
            items={[
                { key: 'active', label: (<Checkbox value="active" onChange={onChange}>Active lesson</Checkbox>) },
                { key: 'review', label: (<Checkbox value="review" onChange={onChange}>In-review lesson</Checkbox>) },
                { key: 'SIC', label: (<Checkbox value="SIC" onChange={onChange}>Still-in-creation lesson</Checkbox>) },
                { key: 'retired', label: (<Checkbox value="retired" onChange={onChange}>Retired lesson</Checkbox>) },
            ]}
        >.
        </Menu>
    return (
        <section>
            <div className="filter__section">
                <p>Filter by:</p>
                <Dropdown
                    id="filter"
                    trigger={['click']}
                    overlay={menu}
                >
                    <p>Status <DownOutlined /></p>
                </Dropdown>
            </div>
            <div className="search__et__create">
                <div className="input__cont">
                    <Input
                        placeholder="Input search text"
                        value={inputValue}
                        onChange={(e) => setInputvalue(e.target.value)}
                    />
                    <div onClick={() => handleSearch()} className="search__cont">
                        <img src={SearchIcon} alt="search Icon" />
                    </div>
                </div>
                <Link to="/tutor/create-lesson">
                    <Button
                        className="create_btn"
                        type="primary"
                    > Create Lesson
                    </Button>
                </Link>
            </div>
            <div className="card__wrapper">
                {isLoading &&
                    <div className="loader_cont">
                        <RequestLoader />
                    </div>}
                {(!isLoading && list.length < 1) &&
                    (
                        <div className="empty_data">
                            <h4>Instructor has no Lesson</h4>
                            <h4>Create a New Lesson</h4>
                        </div>
                    )}
                {(!isLoading && list.length >= 1) && (list.map((listCont, idx) => {
                    return <Lessoncard key={idx} type={listCont} />
                }))}
            </div>
        </section>
    )
}