import React, { memo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { isEqual } from 'lodash'
import Beginner from '../../../../assets/icons/difficulty_beginner.svg'
import Intermediate from '../../../../assets/icons/difficulty_intermediate.svg'
import Advance from '../../../../assets/icons/difficulty_advanced.svg'
import All from '../../../../assets/icons/difficulty.svg'
import user from '../../../../assets/icons/Ellipse.svg'
import star from '../../../../assets/icons/overview_instrc_star.svg'
import review from '../../../../assets/icons/overview_instrc_review.svg'
import students from '../../../../assets/icons/overview_instrc_stud.svg'
import course_icon from '../../../../assets/icons/overview_instrc_play.svg'

const Overview = ({ course, total }) => {
    const [level, setLevel] = useState({
        'image': All,
        'text': 'All'
    })

    // Displaying the neccessary level base on the value returned from the data fetched
    useEffect(() => {
        switch (course?.courseLevel) {
            case 1:
                setLevel({ 'image': All, 'text': 'All' })
                break
            case 2:
                setLevel({ 'image': Beginner, 'text': 'Beginner' })
                break
            case 3:
                setLevel({ 'image': Intermediate, 'text': 'Intermediate' })
                break
            case 4:
                setLevel({ 'image': Advance, 'text': 'Advance' })
                break
            default:
                setLevel({ 'image': All, 'text': 'All' })
                break
        }
    }, [course.courseLevel])

    return (
        <section className="overview_section">
            <div className="about_course">
                <h2>About This Course</h2>
                <p>{course?.description}</p>
                <div className="course_info">
                    <div className="info_item">
                        <div className="img_cont">
                            <img src={level.image} alt={level.text} />
                        </div>
                        <p>{level.text}</p>
                    </div>
                    <div className="info_item">
                        <p className="info_header">{course?.students}</p>
                        <p>Student enrolled</p>
                    </div>
                    <div className="info_item">
                        <p className="info_header">{total}</p>
                        <p>Lectures</p>
                    </div>
                    {/* <div className="info_item">
                        <p className="info_header">{course?.totalHours} hour(s)</p>
                        <p>of video content</p>
                    </div> */}
                </div>
            </div>
            <div className="objectives">
                <h2>What You'll Learn</h2>
                <div className="objectives_items">
                    <ul>
                        {course?.courseObjective && course.courseObjective.map((obj, idx) => (
                            <li key={idx}>{obj}</li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="prerequisite">
                <h2>prerequisite</h2>
                <div className="prerequisite_items">
                    <ul>
                        {course?.prerequisites && course.prerequisites.map((pre, idx) => (
                            <li key={idx}>{pre}</li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="instructor_into">
                <h3>Instructor Information</h3>
                <div className="instructor_details">
                    <div className="img_cont">
                        <img src={course?.instructorPicture === null ? user : course.instructorPicture} alt="instructor snapshot" />
                    </div>
                    <div className="instructor_info">
                        <div className="first_layer">
                            <span>{course?.instructorName}</span>
                            <div>
                                <span className="follow">Follow</span>
                                {/* redirect to a page filtered course by specific instructor */}
                                <Link to="/">View Courses</Link>
                            </div>
                        </div>
                        <div>Senior Front end developer at Google </div>
                        <div className="next_layer">
                            <span>
                                <img src={star} alt="rating" />
                                <span>4.5 star rating</span>
                            </span>
                            <span>
                                <img src={review} alt="review icon" />
                                <span>253 reviews</span>
                            </span>
                            <span>
                                <img src={students} alt="student icon" />
                                <span>13,400 Students</span>
                            </span>
                            <span>
                                <img src={course_icon} alt="course icon" />
                                <span>12 Courses</span>
                            </span>
                        </div>
                    </div>
                </div>
                {course?.instructorBiography !== null ? <p>{course.instructorBiography}</p> : <p>Instructor bio is empty. Kindly update bio in profile setting</p>}
            </div>
        </section>
    )
}

export default memo(Overview, isEqual)