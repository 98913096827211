// import styles
import './careers.scss'
// import images
import handshake from './assets/handshake.png'
import aws from './assets/aws.png'
import jira from './assets/jira.png'
import microsoft from './assets/microsoft.png'
import stripe from './assets/stripe.png'
import zoho from './assets/zoho.png'

const Careers = () => {
    return (
        <section id="careers">
            <div className="container">
                <img className="handshake" src={handshake} alt="handshake" />
                <h1>OUR PARTNERS</h1>
                {/* <p>It is a great pleasure to work with all our best partners</p> */}
                <div className="imgs">
                    <img className="partner-logo" src={stripe} alt="" />
                    <img className="partner-logo" src={aws} alt="" />
                    <img className="partner-logo" src={microsoft} alt="" />
                    <img className="partner-logo" src={jira} alt="" />
                    <img className="partner-logo" src={zoho} alt="" />
                </div>
            </div>
        </section>
    )
}

export default Careers
