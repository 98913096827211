import { useState, useEffect } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'react-router-dom'
import './style.scss'

// Import the Swiper styles
import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Autoplay,
} from 'swiper'

import { findAllCourse } from '../../../../services'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'

import bg from '../assets/hero-bg.png'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

const Carousel = () => {
    const [heroCourses, setHeroCourses] = useState([])

    useEffect(() => {
        const getHeroCourses = async () => {
            try {
                const res = await findAllCourse(4)
                setHeroCourses(res?.data)
            } catch (err) {
                console.error(err)
            }
        }
        getHeroCourses()
    }, [])

    return (
        <div className="container-hero">
            <button className="prev-featured">
                <FaChevronLeft
                    size={30}
                    style={{ fill: '#828282' }}
                    className="chev-btn-left"
                />
            </button>
            <Swiper
                modules={[Autoplay]}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                pagination={true}
                slidesPerView={1}
                spaceBetween={0}
                centeredSlides={true}
                // navigation
                navigation={{
                    nextEl: '.next-featured',
                    prevEl: '.prev-featured',
                }}
                loop={false}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                {heroCourses.map((course, i) => (
                    <SwiperSlide key={i}>
                        <div className="content-wrapper">
                            <div id="premiumPage" className="content">
                                <div className="header-img-con">
                                    <img
                                        className="header-img"
                                        src={bg}
                                        alt="header"
                                    />
                                </div>
                                <h5 className="content-header">
                                    {course?.name}
                                </h5>
                                <p className="text-about">
                                    {course?.description}
                                </p>
                                <Link
                                    to={`/me/courses/course/${course?.id}`}
                                    className="watch-container"
                                    key={i}
                                >
                                    <button
                                        className="watch"
                                    >Watch now
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <button className="next-featured">
                <FaChevronRight
                    size={30}
                    style={{ fill: '#828282' }}
                    className="chev-btn-right"
                />
            </button>
        </div>
    )
}
export default Carousel
