import React from 'react'
// import QuestionData from './QuestionData'
// import Question from './Question'
import { Link } from 'react-router-dom'
import zustechIcon from './assets/zustechIcon.svg'
import profileIcon from './assets/profileIcon.svg'
import subscriptionIcon from './assets/subscriptionIcon.svg'
import takeCourseIcon from './assets/takeCourseIcon.svg'
import spannerIcon from './assets/spannerIcon.svg'
// import AccountProfile from '../StudentSupport/AccountProfile/AccountProfile'

const StudentContactExplore = () => {
    // const [questions] = useState(QuestionData);
    // const redirect = redirect()

    return (
        <>
            {/* BOTTOM SECTION EXPLORE */}
            <section className="bottom-section">
                <h3>Further Topics to Explore</h3>
                <div className="topics-explore">
                    <Link
                        to="/student-support/getting-started"
                        className="explore-links"
                    >
                        <img
                            src={zustechIcon}
                            className="zustech-icon"
                            alt=""
                        />
                        <h3>Getting Started</h3>
                        <p>Learn how Zustech works</p>
                    </Link>
                    <Link
                        to="/student-support/account-setting"
                        className="explore-links"
                    >
                        <img
                            src={profileIcon}
                            className="profile-icon"
                            alt=""
                        />
                        <h3>Accounts/Profile</h3>
                        <p>Manage your accounts and profile settings</p>
                    </Link>
                    <Link
                        to="/student-support/subscription"
                        className="explore-links"
                    >
                        <img
                            src={subscriptionIcon}
                            className="subscription-icon"
                            alt=""
                        />
                        <h3>Subcription/Billings</h3>
                        <p>Manage your subscriptions and payment</p>
                    </Link>
                    <Link
                        to="/student-support/take-course"
                        className="explore-links"
                    >
                        <img
                            src={takeCourseIcon}
                            className="takeCourse-icon"
                            alt=""
                        />
                        <h3>Take our courses</h3>
                        <p>Learn about how to take our courses</p>
                    </Link>
                    <Link
                        to="/student-support/troubleshoot"
                        className="explore-links"
                    >
                        <img
                            src={spannerIcon}
                            className="troubleshoot-icon"
                            alt=""
                        />
                        <h3>Troubleshooting</h3>
                        <p>Having technical problems Check here.</p>
                    </Link>
                </div>
                <p className="here">
                    You can contact us <Link to="/contact-form">here</Link> for
                    more assistance.
                </p>
            </section>
        </>
    )
}

export default StudentContactExplore
