import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Modal, Table, Button, Switch, Radio, Input, Space } from 'antd'
import {
    getAllPlans,
    getSubscriptionHistory,
    findSubscriptionByUserId,
    cancelSubscription,
} from '../../../../services'
import { openNotification } from '../../../../utils/helpers'
import { useGeoLocation } from '../../../../utils'
import { useAuth } from '../../../../hooks'
import { Payment } from '../Payment/index'
import './pricing.scss'
import Ongreen from '../../../../assets/icons/Ongreen.svg'
import Ongray from '../../../../assets/icons/Ongray.svg'
import Onblue from '../../../../assets/icons/Onblue.svg'
// import Naira from './assets/naira.png'
// import Pound from './assets/pound.png'
// import DataDisplay from '../../../../components/Display/DataDisplay'
// import { Loading } from '../../../../components'
// import { StripeApp } from '../../../userDashboard/Settings/Subscription/Stripe/stripe'

const Pricing = () => {
    const [subscriptionPlans, setSubscriptionPlans] = useState([])
    const [dataSource, setDataSource] = useState(null)
    const [isUserSubScribed, setIsUserSubscribed] = useState()
    const [newPlanId, setNewPlanId] = useState()
    const [activeSubInfo, setActiveSubInfo] = useState({})
    const [COPFirstModal, setCOPFirstModal] = useState(false)
    const [openPaymentModal, setOpenpaymentModal] = useState(false)
    const [firstCancelPlanModal, setFirstCancelPlanModal] = useState(false)
    const [secondCancelPlanModal, setSecondCancelPlanModal] = useState(false)
    const [thirdCancelPlanModal, setThirdCancelPlanModal] = useState(false)
    const [fourthCancelPlanModal, setFourthCancelPlanModal] = useState(false)
    const [cancelledPlan, setCancelledPlan] = useState()
    const [offerAccepted, setOfferAccepted] = useState(false)
    const [typedReason, setTypedReason] = useState('')
    const [cancelReason, setCancelReason] = useState('')
    const { country } = useGeoLocation();
    const [symbol, setSymbol] = useState('')
    const loc = document.location.pathname
    const [openModal, setOpenModal] = useState(false)
    const {
        state: { data }
    } = useAuth()

    const history = useHistory()

    // handles dynamic currency for user country location
    useEffect(() => {
        const symbol = () => {
            if (country !== 'NG' && country !== 'ZA' && country !== 'GH' && country !== 'KE') {
                setSymbol('£')
            } else {
                setSymbol('₦')
            }
            console.log(country)
        }
        symbol()

    }, [country])

    const { TextArea } = Input

    useEffect(() => {
        const getSubscriptions = async () => {
            try {
                const response = await getAllPlans()
                setSubscriptionPlans(response)
            } catch (error) {
                openNotification({
                    type: 'error',
                    title: 'Error',
                    message: error.message,
                })
            }
        }

        const getActiveSubscription = async () => {
            try {
                const response = await findSubscriptionByUserId(data.id)
                const listOfGeneralSub = response.filter((a) => a.subscriptionType === 1)
                const res = listOfGeneralSub[0]
                if (response.statusCode !== 404 && Object.keys(res).length > 0) {
                    const subInfo = {}
                    switch (res.planId) {
                        case 1:
                            subInfo['name'] = 'Free Trial'
                            break;
                        case 2:
                            subInfo['name'] = 'Monthly'
                            break;
                        case 11:
                            subInfo['name'] = 'Annual'
                            break;
                        default:
                            subInfo['name'] = 'Free Trial'
                            break;
                    }
                    switch (res.subscriptionStatus) {
                        case 1:
                            subInfo['status'] = 'Active'
                            subInfo['color'] = '#3a86ff'
                            break;
                        case 2:
                            subInfo['status'] = 'Inactive'
                            subInfo['color'] = '#4f4f4f'
                            break;
                        case 3:
                            subInfo['status'] = 'Successfully'
                            subInfo['color'] = '#46d5b3'
                            break;
                        case 4:
                            subInfo['status'] = 'Cancelled'
                            subInfo['color'] = '#ffcc00'
                            break;
                        case 5:
                            subInfo['status'] = 'Expired'
                            subInfo['color'] = '#eb5757'
                            break;
                        default:
                            subInfo['status'] = 'Inactive'
                            subInfo['color'] = '#4f4f4f'
                            break;
                    }
                    subInfo['key'] = res.id
                    subInfo['planId'] = res.planId
                    subInfo['startDate'] = getReadableDate(res.subscription_start_timestamp)
                    subInfo['endDate'] = getReadableDate(res.subscription_end_timestamp)
                    if (res.subscriptionStatus === 1 || res.subscriptionStatus === 4) {
                        setIsUserSubscribed(true)
                    } else {
                        setIsUserSubscribed(false)
                    }
                    setActiveSubInfo(subInfo)
                } else {
                    setIsUserSubscribed(false)
                    setActiveSubInfo({})
                }
            } catch (err) {
                console.error(err.message)
            }
        }
        getSubscriptions()
        getActiveSubscription()

        // eslint-disable-next-line
    }, [data?.id])

    function getReadableDate(data) {
        const option = { month: 'short', day: 'numeric', year: 'numeric' }
        const newDate = new Date(data).toLocaleString('en-US', option)
        return newDate
    }

    const planData = {
        11: {
            'name': 'Annual Plan',
            'sellPoint': 'Best Value',
            'benefits': [
                'Save 30%',
                'Gain unlimited access to courses taught by skilled professionals.',
                'View and Download professional certificates to boost your profile.',
                'Take quizzes and complete assignments.',
                'Learn at your convenience.',
                'Track your progress and performance.',
                'Access course ratings and reviews.',
                'Get tailored course recommendations for your interests and role.',
                'Easily search and navigate content.',
                'Create a personalized learning plan.'
            ]
        },
        2: {
            'name': 'Monthly Plan',
            'sellPoint': 'Most Popular',
            'benefits': [
                'Gain unlimited access to courses taught by skilled professionals.',
                'View and Download professional certificates to boost your profile.',
                'Take quizzes and complete assignments.',
                'Learn at your convenience.',
                'Track your progress and performance.',
                'Access course ratings and reviews.',
                'Get tailored course recommendations for your interests and role.',
                'Easily search and navigate content.',
                'Create a personalized learning plan.',
                'Reach out for customer support via email and phone.'
            ]
        },
        1: {
            'name': '7 Day Free Trial',
            'sellPoint': '',
            'benefits': [
                'Gain unlimited access to courses taught by skilled professionals.',
                'View professional certificates.',
                'Take quizzes and complete assignments.',
                'Learn at your convenience.',
                'Track your progress and performance.',
                'Access course ratings and reviews.',
                'Get tailored course recommendations for your interests and role.',
                'Easily search and navigate content.',
                'Create a personalized learning plan.',
                'Reach out for customer support via email and phone.'
            ]
        }
    }

    async function endplan(planId, reason) {
        try {
            const values = {
                'subscriptionId': planId,
                'reason': reason
            }
            const res = await cancelSubscription(values)
            if (res.message === 'Success') {
                // set state for data
                setCancelledPlan(res.data)
                setThirdCancelPlanModal(false)
                openNotification({
                    type: 'success',
                    title: 'Subscription Cancellation',
                    message: res.message
                })
                setFourthCancelPlanModal(true)
            }
        } catch (err) {
            console.error(err)
            openNotification({
                type: 'error',
                title: 'Subscription Cancellation',
                message: err.message
            })
        }
    }

    function getPlanDetail(id) {
        let amount
        let name
        if (subscriptionPlans) {
            subscriptionPlans.forEach(plan => {
                if (plan.id === id) {
                    amount = plan.amount
                    name = plan.name
                }
            })
        }

        return { amount, name }
    }

    useEffect(() => {
        const getSubHistory = async () => {
            try {
                const arr = []
                const res = await getSubscriptionHistory(1, 300)
                if (res.data.length >= 1) {
                    const data = res.data
                    data.forEach((obj, idx) => {
                        const { amount, name } = getPlanDetail(obj.planId)
                        const subInfo = {}
                        switch (obj.subscriptionStatus) {
                            // Check style variable file for the color codes
                            // Active - secondaryColor, Inactive - grey2, Sucessfully - successColor, cancelled - tertiaryColor, expired - errorColor
                            case 1:
                                subInfo['status'] = 'Active'
                                subInfo['color'] = '#3a86ff'
                                break;
                            case 2:
                                subInfo['status'] = 'Inactive'
                                subInfo['color'] = '#4f4f4f'
                                break;
                            case 3:
                                subInfo['status'] = 'Successfully'
                                subInfo['color'] = '#46d5b3'
                                break;
                            case 4:
                                subInfo['status'] = 'Cancelled'
                                subInfo['color'] = '#ffcc00'
                                break;
                            case 5:
                                subInfo['status'] = 'Expired'
                                subInfo['color'] = '#eb5757'
                                break;
                            default:
                                subInfo['status'] = 'Inactive'
                                subInfo['color'] = '#4f4f4f'
                                break;
                        }
                        arr.push({
                            'key': idx,
                            'startDate': getReadableDate(obj.subscription_start_timestamp),
                            'plan': name,
                            'amount': `${symbol} ${amount}`,
                            'endDate': getReadableDate(obj.subscription_end_timestamp),
                            'status': <h6 style={{ fontSize: '1em', color: `${subInfo.color}` }}>{subInfo.status}</h6>
                        })
                    })
                    setDataSource(arr)
                }
            } catch (error) {
                console.log(error.message)
            }
        }
        if (loc === '/me/subscription') {
            getSubHistory()
        }
    // eslint-disable-next-line
    }, [subscriptionPlans])

    const columns = [
        {
            title: 'Plan:',
            dataIndex: 'plan',
            key: 'plan',
            width: 160
        },
        {
            title: 'Amount:',
            dataIndex: 'amount',
            key: 'amount'
        },
        {
            title: 'Start Date:',
            dataIndex: 'startDate',
            key: 'startDate',
            width: 180
        },
        {
            title: 'End Date:',
            dataIndex: 'endDate',
            key: 'endDate',
            width: 180
        },
        {
            title: 'Status:',
            dataIndex: 'status',
            key: 'status',
            width: 100
        }
    ];

    return (
        <section id="pricingg">
            {/* <DataDisplay /> */}
            <div className="wave-bg">
                { loc === '/pricing' ? (
                    <div className="header">
                        <h2>Pricing</h2>
                        <p>
                            We provide competitive and pocket friendly pricing for our
                            wide range of courses.
                        </p>
                    </div>
                ) : (
                    <div className="history_link">
                        <div className="active_sub">
                            {(isUserSubScribed && Object.keys(activeSubInfo).length >= 1) &&
                            <div className="card_cont" id="current_plan_cont">
                                <h3>Subscription</h3>
                                <div className="main_view">
                                    <section className="main_info">
                                        <div className="primary_info">
                                            <h2>{activeSubInfo.name} <span>Plan</span></h2>
                                            <h6>Status: <span style={{ fontSize: '1em', color: `${activeSubInfo.color}` }}>{activeSubInfo.status}</span></h6>
                                        </div>
                                        <div className="dates">
                                            <p><span className="start">Start:  </span><span>{activeSubInfo.startDate}</span></p>
                                            <p><span className="expire">Expires:  </span><span>{activeSubInfo.endDate}</span></p>
                                        </div>
                                    </section>
                                    <section className="upgrade">
                                        <p>Change from a {activeSubInfo.name} plan to a {activeSubInfo.planId !== 2 ? 'Monthly' : 'Annual' } plan</p>
                                        <Button
                                            type="primary"
                                            style={{
                                                borderRadius: '8px'
                                            }}
                                            onClick={() => {
                                                setCOPFirstModal(true)
                                                if (activeSubInfo.planId !== 2) {
                                                    setNewPlanId(2)
                                                } else {
                                                    setNewPlanId(11)
                                                }
                                            }}
                                        >Change plan
                                        </Button>
                                    </section>
                                    <section className="renewal">
                                        <p>Automatically renew your plan</p>
                                        <div className="CTA">
                                            <Switch defaultChecked />
                                        </div>
                                    </section>
                                </div>
                                <div
                                    className="cancel_btn"
                                    id="cancel_planBtn"
                                    onClick={() => {
                                        if (activeSubInfo.planId !== 1) {
                                            setFirstCancelPlanModal(true)
                                        } else {
                                            openNotification({
                                                title: 'Plan Cancellation',
                                                type: 'error',
                                                message: 'Free Plan can\'t be cancelled'
                                            })
                                        }
                                    }}
                                >Cancel plan
                                </div>
                            </div>}
                        </div>
                        <div
                            id="paymtHisBtn"
                            onClick={() => { setOpenModal(true) }}
                        >Payment History
                        </div>
                    </div>
                )}
                <div className="pricing-container">
                    <div className="content container">
                        {/* Start of pricing cards */}
                        <div className="pricing-cards">
                            {/* FIRST PRICE CARD */}
                            {/* this card (first card) should not show when user has no active subs */}
                            {!isUserSubScribed &&
                            <div className="first-card cards">
                                {subscriptionPlans.slice(0, 1).map((plan) => (
                                    <div key={plan.id}>
                                        <div className="top left">
                                            <p className="frequency-long">7 Days {plan.name}</p>
                                            <h4 id="currency-price">{symbol}{plan.amount}</h4>
                                        </div>
                                        <div>
                                            <ul className="first-feature feature">
                                                {planData[plan.id]['benefits'].map((goods, idx) => (
                                                    <li key={idx} className="first-feature-item">
                                                        <div>
                                                            <img src={Ongray} alt="" />
                                                        </div>
                                                        <p>{goods}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <Link
                                            id={`paymentBtn-${plan?.id}`}
                                            to={`payment/${plan?.id}`}
                                            className="btn-subscription btn-first"
                                            onClick={() => {
                                                // Storing the id of course to subscribe for and the duration for calculating total amount
                                                const paymentInfo = {
                                                    'subscriptionType': 1
                                                }
                                                localStorage.setItem('SubCourseId', JSON.stringify(paymentInfo))
                                            }}
                                        >
                                            Get Started
                                        </Link>
                                    </div>
                                ))}
                            </div>}
                            {/* MIDDLE PRICE CARD */}
                            <div className={isUserSubScribed ? 'second-card center cards simple' : 'second-card center cards'}>
                                {subscriptionPlans.slice(1, 2).map((plan) => (
                                    <div key={plan.id} className={activeSubInfo.planId === plan.id ? 'head active' : 'head'}>
                                        <div className="top top-center">
                                            <p id="statement">{planData[plan.id]['sellPoint']}</p>
                                            <p className="frequency-long">{plan.name}</p>
                                            <h4 id="currency-price">{symbol}{plan.amount} <span id="span">/mo</span></h4>
                                        </div>
                                        <div>
                                            <ul className="second-feature feature">
                                                {planData[plan.id]['benefits'].map((goods, idx) => (
                                                    <li key={idx} className="second-feature-item">
                                                        <div>
                                                            <img src={Ongreen} alt="" />
                                                        </div>
                                                        <p>{goods}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <Link
                                            id={`paymentBtn-${plan?.id}`}
                                            to={`payment/${plan?.id}`}
                                            className="btn-subscription btn-second"
                                            onClick={() => {
                                                // Storing the id of course to subscribe for and the duration for calculating total amount
                                                const paymentInfo = {
                                                    'subscriptionType': 1
                                                }
                                                localStorage.setItem('SubCourseId', JSON.stringify(paymentInfo))
                                            }}
                                        >
                                            Get Started
                                        </Link>
                                    </div>
                                ))}
                            </div>
                            {/* LAST PRICE CARD */}
                            <div className="last-card cards">
                                {subscriptionPlans.slice(2, 3).map((plan) => (
                                    <div key={plan.id} className={activeSubInfo.planId === plan.id ? 'head active' : 'head'}>
                                        <div className="top right">
                                            <p id="statement">{planData[plan.id]['sellPoint']}</p>
                                            <p className="frequency-long">{plan.name}</p>
                                            <h4 id="currency-price">{symbol}{plan.amount} <span id="span">/yr</span></h4>
                                        </div>
                                        <div>
                                            <ul className="last-feature feature">
                                                {planData[plan.id]['benefits'].map((goods, idx) => (
                                                    <li key={idx} className="third-feature-item">
                                                        <div>
                                                            <img src={Onblue} alt="" />
                                                        </div>
                                                        <p>{goods}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <Link
                                            id={`paymentBtn-${plan?.id}`}
                                            to={`payment/${plan?.id}`}
                                            className="btn-subscription btn-last"
                                            onClick={() => {
                                                // Storing the id of course to subscribe for and the duration for calculating total amount
                                                const paymentInfo = {
                                                    'subscriptionType': 1
                                                }
                                                localStorage.setItem('SubCourseId', JSON.stringify(paymentInfo))
                                            }}
                                        >
                                            Get Started
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* PAYMENT HISTORY MODAL */}
            <Modal
                open={openModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => { setOpenModal(false) }}
                width={800}
                id="paymtHistoryModal"
            >
                <h2 className="title">Payment History</h2>
                <div className="histoy_detail">
                    {dataSource ? (<Table
                        id="historyTable"
                        dataSource={dataSource}
                        columns={columns}
                        scroll={{
                            y: 500,
                        }}
                    />) : (<h4>No Payment History Yet</h4>)}
                </div>
            </Modal>
            {/* CHANGE OF PLAN FIRST MODAL */}
            <Modal
                open={COPFirstModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => { setCOPFirstModal(false) }}
                width={500}
                id="ChangeOfPlanFirstModal"
                className="ChangeOfPlanFirstModal"
            >
                <h2 className="title">Change to Annual Plan</h2>
                {
                    // check for newPlanId in Plan data, then render it
                    newPlanId !== undefined &&
                    <div className="product_cont">
                        <h3>{planData[newPlanId]?.name}</h3>
                        <p>{planData[newPlanId]?.sellPoint}</p>
                        <ul>
                            {planData[newPlanId]?.benefits.map((itm, idx) => (
                                <li key={idx}>
                                    <div>
                                        <img src={Onblue} alt="" />
                                    </div>
                                    <p>{itm}</p>
                                </li>
                            ))}
                        </ul>
                        <Button
                            type="primary"
                            onClick={() => {
                                setCOPFirstModal(false)
                                setOpenpaymentModal(true)
                                // history.push(`payment/${newPlanId}`)
                            }}
                        >Get Started
                        </Button>
                    </div>
                }
            </Modal>
            {/* Payment modal */}
            <Modal
                open={openPaymentModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => { setOpenpaymentModal(false) }}
                width={600}
                closeIcon={null}
                id="paymentModal"
                className="paymentModal"
            >
                <Payment plnId={newPlanId} prevPlanId={activeSubInfo.planId} offerAccepted={offerAccepted} />
            </Modal>
            {/* CANCEL PLAN FIRST MODAL */}
            <Modal
                open={firstCancelPlanModal}
                // open={true}
                centered
                footer={null}
                closeIcon={null}
                closable={false}
                // onCancel={() => { setFirstCancelPlanModal(false) }}
                width={500}
                id="CancelPlanFirstModal"
                className="CancelPlanFirstModal"
            >
                <h2 className="title">Are you sure you want to cancel your plan?</h2>
                <p>You will lose access to our amazing courses and the wonderful features we offer when the current plan expires.</p>
                <div
                    className="btn_cont"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        marginTop: '1em'
                    }}
                >
                    <Button
                        onClick={() => {
                            setSecondCancelPlanModal(true)
                            setFirstCancelPlanModal(false)
                        }}
                        style={{
                            width: '30%',
                            fontSize: '1em',
                            fontWeight: '500',
                            borderRadius: '8px'
                        }}
                    >Cancel Plan
                    </Button>
                    <Button
                        onClick={() => { setFirstCancelPlanModal(false) }}
                        type="primary"
                        style={{
                            width: '30%',
                            fontSize: '1em',
                            fontWeight: '500',
                            borderRadius: '8px'
                        }}
                    >Go Back
                    </Button>
                </div>
            </Modal>
            {/* SECOND CANCEL PLAN MODAL */}
            <Modal
                open={secondCancelPlanModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => {
                    setSecondCancelPlanModal(false)
                    setCancelReason('')
                    setTypedReason('')
                }}
                width={500}
                id="CancelPlanSecondModal"
                className="CancelPlanSecondModal"
            >
                <h2 className="title">Why are you cancelling?</h2>
                <div className="options">
                    <Radio.Group onChange={(e) => { setCancelReason(e.target.value) }} value={cancelReason}>
                        <Space direction="vertical">
                            <Radio value="expensive">It exceeds my budget (its too expensive for me)</Radio>
                            <Radio value="schedule">I am not using the products enough because of tight and busy schedules</Radio>
                            <Radio value="discountEnticed">I only signed up to use the initial introductory discount</Radio>
                            <Radio value="notValueable">I am not getting equal value for my money</Radio>
                            <Radio value="interestChanged">My interests have changed</Radio>
                            <Radio value="others">Others</Radio>
                        </Space>
                    </Radio.Group>
                    {cancelReason === 'others' &&
                        <TextArea
                            maxLength={200}
                            showCount
                            style={{
                                height: 120,
                                marginBottom: 24,
                                marginTop: 20,
                            }}
                            placeholder="Tell us why you are cancelling"
                            value={typedReason}
                            onChange={(e) => {
                                setTypedReason(e.target.value)
                            }}
                        />}
                </div>
                <div
                    className="btn_cont"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        marginTop: '1em'
                    }}
                >
                    <Button
                        type="primary"
                        onClick={() => {
                            if (cancelReason === '' || (cancelReason === 'others' && typedReason === '')) {
                                openNotification({
                                    title: 'Cancellation Reason',
                                    message: 'Choose a reason for cancelling',
                                    type: 'error'
                                })
                            } else {
                                setSecondCancelPlanModal(false)
                                setThirdCancelPlanModal(true)
                            }
                        }}
                        style={{
                            width: '30%',
                            fontSize: '1em',
                            fontWeight: '500',
                            borderRadius: '8px'
                        }}
                    >Proceed
                    </Button>
                </div>
            </Modal>
            {/* THIRD CANCLE PLAN MODAL */}
            <Modal
                open={thirdCancelPlanModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => { setThirdCancelPlanModal(false) }}
                width={500}
                id="CancelPlanThirdModal"
                className="CancelPlanThirdModal"
            >
                <h2 className="title">We want to help you to stay!</h2>
                <p>We're <span style={{ color: '#3a86ff' }}>extending a generous 50% discount</span> to encourage you to stay on and achieve your learning objectives..</p>
                <div
                    className="btn_cont"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        marginTop: '1em'
                    }}
                >
                    <Button
                        onClick={() => {
                            setThirdCancelPlanModal(false)
                            setOfferAccepted(true)
                            setOpenpaymentModal(true)
                            if (activeSubInfo.planId !== 2) {
                                setNewPlanId(2)
                            } else {
                                setNewPlanId(11)
                            }
                            // opem payment modal for 50% off offer
                        }}
                        type="primary"
                        style={{
                            width: '30%',
                            fontSize: '1em',
                            fontWeight: '500',
                            borderRadius: '8px'
                        }}
                    >Accept Offer
                    </Button>
                    <Button
                        onClick={() => {
                            // request to cancel plan
                            if (typedReason === '') {
                                endplan(activeSubInfo.planId, cancelReason)
                            } else {
                                endplan(activeSubInfo.planId, typedReason)
                            }
                        }}
                        style={{
                            width: '30%',
                            fontSize: '1em',
                            fontWeight: '500',
                            borderRadius: '8px'
                        }}
                    >Cancel Plan
                    </Button>
                </div>
            </Modal>
            {/* FOURTH CANCLE PLAN MODAL */}
            <Modal
                open={fourthCancelPlanModal}
                // open={true}
                centered
                footer={null}
                onCancel={() => { setFourthCancelPlanModal(false) }}
                width={500}
                id="CancelPlanThirdModal"
                className="CancelPlanThirdModal"
            >
                <h2 className="title">We don’t want to see you go!</h2>
                <p>Your annual subscription has been successfully canceled,</p>
                <p>Your curret plan will expire on [{getReadableDate(cancelledPlan?.subscription_end_timestamp)}].</p>
                <p style={{ color: '#3a86ff', marginBlock: '.6em' }}>You can still take advantage of our 50% discount offer by simply clicking the Accept offer button below.</p>
                <p>We look forward to welcoming you back soon!</p>
                <div
                    className="btn_cont"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        marginTop: '1em'
                    }}
                >
                    <Button
                        onClick={() => {
                            setFourthCancelPlanModal(false)
                            setOfferAccepted(true)
                            setOpenpaymentModal(true)
                            if (activeSubInfo.planId !== 2) {
                                setNewPlanId(2)
                            } else {
                                setNewPlanId(11)
                            }
                            // opem payment modal for 50% off offer
                        }}
                        type="primary"
                        style={{
                            width: '30%',
                            fontSize: '1em',
                            fontWeight: '500',
                            borderRadius: '8px'
                        }}
                    >Accept Offer
                    </Button>
                    <Button
                        onClick={() => {
                            // request to cancel plan
                            history.push('/me')
                        }}
                        style={{
                            width: '30%',
                            fontSize: '1em',
                            fontWeight: '500',
                            borderRadius: '8px'
                        }}
                    >Continue
                    </Button>
                </div>
            </Modal>
        </section>
    )
}

export default Pricing
