import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Dropdown, Avatar, Pagination } from 'antd'
// import 'antd/dist/antd.css'
import { findAllCourses } from '../../../../services'
import { openNotification } from '../../../../utils'
import './style.scss'
import arrowDown from '../../assets/polygon.png'

export const CourseRevieww = () => {
    const [courses, setCourses] = useState([])
    // console.log(courses)
    const [filteredCourses, setFilteredCourses] = useState([])
    // console.log(filteredCourses)
    // console.log(setFilteredCourses)
    const [input, setInput] = useState('')
    console.log(input)
    const [rerenderTrigger, setRerenderTrigger] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        // If courses change, reset the current page to 1
        setCurrentPage(1)
    }, [courses])

    // HANDLE SEARCH FILTER LOGIC
    const handleSearchChange = (e) => {
        const searchInput = e.target.value.toLowerCase()
        setInput(searchInput)
        const filtered = courses.filter((course) => {
            return (
                course.name.toLowerCase().includes(searchInput) ||
                course.instructorName.toLowerCase().includes(searchInput)
            )
        })
        setFilteredCourses(filtered)
        setCurrentPage(1) // Reset to the first page when the search changes
    }

    // LOGIC for Paginaton
    const handlePageChange = (page) => {
        setCurrentPage(page)
    }
    const itemsPerPage = 5
    // calculate the range of items to display based on the current page
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    const currentItems = filteredCourses.slice(startIndex, endIndex)

    const filterCourses = (e) => {
        setRerenderTrigger((prevState) => !prevState)
        if (e.domEvent.target.innerText === 'Course Name A ▲ Z') {
            setCourses(
                courses.sort((a, b) => {
                    return a.name.localeCompare(b.name)
                })
            )
        } else if (e.domEvent.target.innerText === 'Course Name A ▼ Z') {
            setCourses(
                courses.sort((a, b) => {
                    return b.name.localeCompare(a.name)
                })
            )
        } else if (e.domEvent.target.innerText === 'Instructor Name A ▲ Z') {
            setCourses(
                courses.sort((a, b) => {
                    return a.instructorName.localeCompare(b.instructorName)
                })
            )
        } else if (e.domEvent.target.innerText === 'Instructor Name A ▼ Z') {
            setCourses(
                courses.sort((a, b) => {
                    return b.instructorName.localeCompare(a.instructorName)
                })
            )
        } else {
            if (e.domEvent.target.innerText === 'Rejected') {
                getCourses('CourseApproval=3&')
            } else if (e.domEvent.target.innerText === 'Accepted') {
                getCourses('CourseApproval=2&')
            } else if (e.domEvent.target.innerText === 'Pending') {
                getCourses('CourseApproval=1&')
            } else if (e.domEvent.target.innerText === 'All') {
                getCourses('')
            }
        }
    }

    const menuCourse = (
        <Menu>
            <Menu.Item onClick={filterCourses}>
                <p className="dropdown-text">Course Name A ▲ Z</p>
            </Menu.Item>
            <Menu.Item onClick={filterCourses}>
                <p className="dropdown-text">Course Name A ▼ Z</p>
            </Menu.Item>
        </Menu>
    )
    const menuInstructor = (
        <Menu>
            <Menu.Item onClick={filterCourses}>
                <p className="dropdown-text">Instructor Name A ▲ Z</p>
            </Menu.Item>
            <Menu.Item onClick={filterCourses}>
                <p className="dropdown-text">Instructor Name A ▼ Z</p>
            </Menu.Item>
        </Menu>
    )
    const menuApplication = (
        <Menu>
            <Menu.Item onClick={filterCourses}>
                <p className="dropdown-text">Rejected</p>
            </Menu.Item>
            <Menu.Item onClick={filterCourses}>
                <p className="dropdown-text">Accepted</p>
            </Menu.Item>
            <Menu.Item onClick={filterCourses}>
                <p className="dropdown-text">Pending</p>
            </Menu.Item>
            <Menu.Item onClick={filterCourses}>
                <p className="dropdown-text">All</p>
            </Menu.Item>
        </Menu>
    )

    const getCourses = async (string) => {
        // setLoading(true)
        try {
            const response = await findAllCourses(string, 1, 40)
            setCourses(response.data)
        } catch (error) {
            openNotification({
                type: 'error',
                title: 'Error',
                message: error.message,
            })
        }
        // setLoading(false)
    }

    useEffect(() => {
        getCourses('')
    }, [])

    useEffect(() => {
        if (courses !== filteredCourses) {
            setFilteredCourses(courses)
        }
    }, [filteredCourses, rerenderTrigger, courses])

    const applicationStatus = (val) => {
        if (val === 2) {
            return (
                <div className="approved">
                    <p>Approved</p>
                </div>
            )
        } else if (val === 1) {
            return (
                <div className="processing">
                    <p>Processing</p>
                </div>
            )
        } else if (val === 3) {
            return (
                <div className="rejected">
                    <p>Rejected</p>
                </div>
            )
        }
    }

    const profilePic = (val) => {
        if (val?.profilePicture) {
            return (
                <Avatar size="large" id="profilePic" src={val.profilePicture} />
            )
        }
    }

    return (
        <div className="instructorApplications">
            <div className="heading">Courses Review Overview</div>
            <h5 className="overview">Welcome to the Admin Dashboard</h5>
            <div className="search-review-container">
                <input
                    className="search--bar"
                    type="text"
                    placeholder="Search Courses..."
                    name=""
                    onChange={handleSearchChange}
                    value={input}
                />
                <div className="review">
                    <div className="review-box">
                        <h6>Courses Pending Review from Admin</h6>
                        <p>00</p>
                    </div>
                </div>
            </div>
            <div className="tableContainer">
                <table className="historyTable">
                    <thead>
                        <tr className="categories">
                            <th className="left">Course ID</th>
                            <th className="center">Date</th>
                            <Dropdown className="dropdown" overlay={menuCourse}>
                                <th className="center">
                                    Course Name <img src={arrowDown} alt="" />
                                </th>
                            </Dropdown>
                            <Dropdown
                                className="dropdown"
                                overlay={menuInstructor}
                            >
                                <th className="center">
                                    Instructor <img src={arrowDown} alt="" />
                                </th>
                            </Dropdown>
                            <Dropdown
                                className="dropdown"
                                overlay={menuApplication}
                            >
                                <th className="right">
                                    Application Status{' '}
                                    <img src={arrowDown} alt="" />
                                </th>
                            </Dropdown>

                            {/* <th>Amount:</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems?.map((val) => {
                            return (
                                <tr key={val.id}>
                                    <Link
                                        to={
                                            '/admin/courses/courses-review/' +
                                            val.id
                                        }
                                    >
                                        <td className="left">
                                            <div className="instructor">
                                                <p>{val.id}</p>
                                            </div>
                                        </td>
                                    </Link>
                                    <td className="center">
                                        <div
                                            className="instructor"
                                            style={{
                                                flexDirection: 'column',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {val.createdDate}
                                        </div>
                                    </td>
                                    {/* users */}
                                    <td className="center">
                                        <div className="instructor">
                                            <p>{val.name}</p>
                                        </div>
                                    </td>
                                    {/* Application Status */}
                                    <td className="center">
                                        <div className="instructor">
                                            {profilePic(val)}
                                            <p>{val.instructorName}</p>
                                        </div>
                                    </td>
                                    {/* activity */}
                                    <td className="right">
                                        {applicationStatus(val.courseApproval)}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <Pagination
                defaultCurrent={1}
                current={currentPage}
                pageSize={itemsPerPage}
                // total={totalItems}
                total={100}
                onChange={handlePageChange}
            />
        </div>
    )
}