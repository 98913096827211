import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { softSearchCourses } from '../../../../../services'

import './searchbar.scss'

const SearchBar = () => {
    // const [input, setInput] = useState('')
    const [searchResult, setSearchResult] = useState([])

    const handleChange = async (event) => {
        const { value } = event.target
        // setInput(value)
        if (value.length !== 0) {
            try {
                // fetch data
                const response = await softSearchCourses(value)
                setSearchResult(response?.data)
            } catch (error) {
                console.error(error)
            }
        } else {
            setSearchResult([])
        }
    }

    const debouce = (func) => {
        let timeout
        return function (...args) {
            const context = this
            if (timeout) clearTimeout(timeout)
            timeout = setTimeout(() => {
                timeout = null
                func.apply(context, args)
            }, 500)
        }
    }

    const optimizedFunc = useCallback(debouce(handleChange), []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div id="search">
            <div className="search-input">
                <input
                    // value={input}
                    onChange={optimizedFunc}
                    // onChange={handleChange}
                    type="text"
                    placeholder="Search for courses"
                />
                {searchResult.length !== 0 && (
                    <div className="search-result">
                        {searchResult.map((value) => (
                            <Link
                                key={value.name}
                                to={`courses/course/${value?.id}`}
                            >
                                <div className="search-course-div">
                                    <h4>{value.name}</h4>
                                </div>
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SearchBar
