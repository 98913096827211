import { useState, useEffect } from 'react'

import { findAllCourse } from '../../../../services'
// import CourseCard from '../../../userDashboard/Courses/CourseCard/CourseCard'
import CourseCard from '../../../userDashboard/premiumCourses/CourseCard/CourseCard'

import './courseCategories.scss'

const LandingCourseCard = ({ typeOneCourse }) => {
    const [popularCourses, setPopularCourses] = useState([])
    // const [topPicksCourses, setTopPicksCourses] = useState([])

    useEffect(() => {
        const getSixCourses = async () => {
            try {
                const res = await findAllCourse(10)
                setPopularCourses(res?.data.filter(course => course.courseType !== 1))
            } catch (err) {
                console.error(err)
            }
        }
        getSixCourses()
    }, [])

    // useEffect(() => {
    //     const getThreeCourses = async () => {
    //         try {
    //             const res = await findAllCourse(15)
    //             setTopPicksCourses(res?.data.filter(course => course.courseType === 1))
    //         } catch (err) {
    //             console.error(err)
    //         }
    //     }
    //     getThreeCourses()
    // }, [])

    return (
        <div className="course_cont">
            <div className="courses-container">
                {popularCourses.map((course) => (
                    <CourseCard
                        key={course.id}
                        course={course}
                    />
                ))}

                {typeOneCourse && typeOneCourse.map((course) => (
                    <CourseCard
                        key={course.id}
                        course={course}
                    />
                ))}

                {(typeOneCourse && typeOneCourse.length < 1 && popularCourses.length < 1) &&
                    <div className="mimicked_errorMessage">
                        Error occured while fectching course.
                    </div>}
            </div>
        </div>
    )
}

export default LandingCourseCard
